import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { useTranslation } from 'react-i18next';
import { Container } from "react-bootstrap";
import { TENANT_BUCKET } from '../../common/constants'
import { useMiddletier } from "../../common/middletier";

import "./template_01.css";

const SpeakerAnnouncement = (props) => {

  const { desktopToggle, mobileToggle, iconToggle, titleToggle } = props;
  const { i18n } = useTranslation();
  const [speakerAnnouncementContent, setSpeakerAnnouncementContent] = useState();
  const { query } = useMiddletier();

  useEffect(() => {
    const method = 'getSpeakerAnnouncements'

    query({
      method,
      params: [
        { code: 'language', graphqlType: 'String', required: true, value: i18n.resolvedLanguage },
      ],
      attributes: []

    }).then(({ data }) => {
      // console.log('getSpeakerAnnouncements', data[method])
      setSpeakerAnnouncementContent(data[method][0]);

    }).catch((error) => {
      console.log('error: ', error)
    })

  }, [query, i18n.resolvedLanguage]);

  return (
    (desktopToggle || mobileToggle) && <>
      <section className={`section ${!desktopToggle && "d-lg-none"} ${!mobileToggle && "d-none d-lg-block"}`} id="speaker_announcement_01">
        <div className="color_secondary speakerAnnouncement_body">
          <Container className="speakerAnnouncement_container">
            <div className="speakerAnnouncement_titleContainer">
              {iconToggle && <div className="speakerAnnouncement_iconContainer">
                <img src={`${TENANT_BUCKET}/icon/speaker.png`} alt="speaker" />
              </div>}
              {titleToggle && (<>News</>)}
            </div>

            <Marquee gradientColor={"transparent"} speed={"45"} className="font_h4 speakerAnnouncement_content">
              {speakerAnnouncementContent?.message}
            </Marquee>
          </Container>
        </div>
      </section>
    </>
  );
}


export default SpeakerAnnouncement;