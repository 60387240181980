import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { TENANT_BUCKET, PAGE_DATA } from '../../common/constants'
import { useMiddletier } from "../../common/middletier";
import { useMediaQuery } from 'react-responsive';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from "swiper";
import { useStoreDispatch, useStoreState, APP_STORE_ACTION } from '../../common/storeContext';
import dayjs from 'dayjs';
import { useNavigate, useLocation } from "react-router-dom";
import "./template_01.css";



import "swiper/css/pagination";

const Announcement = ({ annLogoToggle, ...props }) => {
  // const { annLogoToggle } = props
  const appDispatch = useStoreDispatch()
  const appState = useStoreState()
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { query, mutation } = useMiddletier();
  const [announcement, setAnnouncement] = useState();
  const [showAnnouncement, setShowAnnouncement] = useState(false);

  // const [isChecked, setIsChecked] = useState(false);
  const bgImage = `${TENANT_BUCKET}/background/bg_announcement.png`;
  const location = useLocation();

  useEffect(() => {

    const today = dayjs().format('YYYY-MM-DD');
    // let localStorageName = ""
    // let checkPublic = true
    let checkLastLoginStatus = false

    const path = location.pathname;
    const getLocationValue = Object.values(PAGE_DATA).find(item => item.url === path)?.value || null;

    console.log('announcement location', getLocationValue)

    if (appState.loggedIn === false) {

      if (appState.loginWithToken === true) {
        mutation([
          {
            index: 'loginWithTokenByMember',
            method: 'loginWithTokenByMember',
            params: [],
            attributes: []
          }
        ])
          .then(({ data }) => {
            // console.log('login token')

            appDispatch({ type: APP_STORE_ACTION.LOGIN, payload: { ...data['loginWithTokenByMember'] } })
            checkLastLoginStatus = true
          })
          .catch((error) => {
            // console.log('login error')
            console.error(error)
            appDispatch({ type: APP_STORE_ACTION.SET_LOGIN_WITH_TOKEN, payload: false })
            if (error.toString().includes("No Access Token") || error.toString().includes("tokens")) {
              appDispatch({ type: APP_STORE_ACTION.LOGOUT })
              navigate('/')
            }
          })
      } else {
        // console.log('login false')
        checkLastLoginStatus = true
      }
    } else {
      // console.log('login true')
      checkLastLoginStatus = true
    }

    const localStorageName = appState.loggedIn ? 'announcement_private_hideDate' : 'announcement_public_hideDate'
    const getLastRead = localStorage.getItem(localStorageName);

    if (getLastRead !== today && checkLastLoginStatus) {
      const method = 'getAnnouncements'

      query({
        method,
        params: [
          { code: 'language', graphqlType: 'String', required: true, value: i18n.resolvedLanguage },
          { code: 'isPublic', graphqlType: 'Boolean', required: true, value: appState.loggedIn ? false : true },
          { code: 'showIn', graphqlType: 'String', required: false, value: getLocationValue || '' }
        ],
        attributes: []

      }).then(({ data }) => {
        setShowAnnouncement(true)
        setAnnouncement(data[method]);


      }).catch((error) => {
        console.log('error: ', error)
      })
    }


  }, [query, i18n.resolvedLanguage, appState.loggedIn, mutation, appDispatch, appState.loginWithToken, navigate, location]);

  const hideForToday = (event) => {
    // setIsChecked(event.target.checked);
    const today = dayjs().format('YYYY-MM-DD');

    if (event.target.checked) {
      if (appState.loggedIn) {
        localStorage.setItem("announcement_private_hideDate", today);
      } else {
        localStorage.setItem("announcement_public_hideDate", today);
      }
    } else {
      if (appState.loggedIn) {
        localStorage.removeItem("announcement_private_hideDate");
      } else {
        localStorage.removeItem("announcement_public_hideDate");
      }
    }

  };

  const onClickClose = () => {
    // console.log('hide for today: ',isChecked)
    // const today = dayjs().format('YYYY-MM-DD');

    // if (isChecked) {
    //   if (appState.loggedIn) {
    //     localStorage.setItem("announcement_private_hideDate", today);
    //   } else {
    //     localStorage.setItem("announcement_public_hideDate", today);
    //   }
    // }
    setShowAnnouncement(false)
    console.log('close ann')
    props.onHide()

  }

  const paginations = {
    clickable: true,
  };

  const autoplays = {
    delay: 2500,
    disableOnInteraction: false,
  }

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)'
  })

  return (<>
    {(announcement?.length > 0 && showAnnouncement) && (

      <Modal
        {...props}
        id="announcement_01"
        animation={showAnnouncement ? true : false}
        onHide={onClickClose}
        centered
      >

        <div className="announcement_body" style={{ background: `url(${bgImage})`, backgroundSize: 'cover' }}>
          <Modal.Header className="announcement_header">
            <Modal.Title className="font_h3 announcement_header_title">
              {annLogoToggle && (<img src={`${TENANT_BUCKET}/icon/ann_logo.png`} alt="Logo" />)}
              <span>{t('Announcement')}</span>
            </Modal.Title>
            <button type="button" className="btn-close" aria-label="Close" onClick={onClickClose}></button>
          </Modal.Header>
          <Modal.Body>
            <Swiper
              // spaceBetween={5}
              slidesPerView={1}
              // onSwiper={(swiper) => {
              //   swiperRef.current = swiper;
              // }}
              pagination={paginations}
              autoplay={autoplays}
              className="announcementTab"
              modules={[Autoplay, Pagination]}
            >
              {
                announcement?.map(function (item, index) {
                  // item = appState.gameProviders[item] ?? ""

                  return (
                    <SwiperSlide key={item.id} className="announcement_content">
                      <div className=" ">
                        {isDesktopOrLaptop ? item?.desktop_image_url &&
                          (<img src={`${item.desktop_image_url}`} alt="ann_img" className="ann_img" />) :
                          item?.mobile_image_url &&
                          (<img src={`${item.mobile_image_url}`} alt="ann_img" className="ann_img" />)
                        }
                        <div dangerouslySetInnerHTML={{ __html: item?.message }}></div>

                      </div>
                    </SwiperSlide>
                  )
                })}
            </Swiper>
            <Form.Check type='checkbox' >
              <Form.Check.Input type='checkbox' onChange={hideForToday} />
              <Form.Check.Label className="font_h5">Hide For Today</Form.Check.Label>
            </Form.Check>
          </Modal.Body>
          <Modal.Footer>
            <button className="font_button color_button announcement_btnClose" onClick={onClickClose}>Close</button>
          </Modal.Footer>
        </div>
      </Modal>)
    }</>
  );
}

export default Announcement;
