import React, { useState } from "react";
import { useStoreDispatch, APP_STORE_ACTION } from '../../../../common/storeContext';
import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import Title from '../../../pageTitle/default.js';
import { useForm } from "react-hook-form";

import { useMiddletier } from "../../../../common/middletier";
import Loading from '../../../../components/Loading';
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
    const appDispatch = useStoreDispatch()
    // const appState = useStoreState()

    const { t } = useTranslation();
    const [isLoading, setLoading] = useState(false);
    const { mutation } = useMiddletier()
    // const navigate = useNavigate();


    const {
        register: change_password,
        getValues,
        formState: { errors, isValid },
        handleSubmit,
        reset,
    } = useForm({
        mode: "onChange",
    });

    const onSubmit = (data) => {
        setLoading(true)
        const method = 'changeFriendTransferPasswordByMember'

        mutation([{
            method,
            params: [
                { code: 'oldPassword', graphqlType: 'String', required: true, value: data.current_password },
                { code: 'newPassword', graphqlType: 'String', required: true, value: data.new_password },
            ],
            attributes: []

        }])
            .then(({ data }) => {
                setLoading(false)
                appDispatch({
                    type: APP_STORE_ACTION.SHOW_ALERT,
                    payload: { description: 'Change Password Successful', typeAlert: 'success' }
                });
            })
            .catch((error) => {
                setLoading(false)
                console.log('error: ', error)
                if (error?.networkError?.statusCode === 401) {
                    appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                } else {
                    appDispatch({
                        type: APP_STORE_ACTION.SHOW_ALERT,
                        payload: { description: error.message.toString(), typeAlert: 'error' }
                    });
                }
            })
        reset()

    };



    return (
        <>
            <Form className="changePassword_form" onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="form_input_wrap" >
                    <div className="input_wrap">
                        <Form.Label>{t('current_password')}
                            <span className="form_required_input">* {t('required_fill_in')}</span>
                        </Form.Label>
                        <Form.Control
                            type="password"
                            // placeholder={t('current_password')}
                            className=" input_changePassword_currentPassword"
                            aria-label="changePassword_currentPassword"
                            onKeyDown={(event) => {
                                if (event.key === ' ') {
                                    event.preventDefault();
                                }
                            }}
                            onInput={(event) => {
                                if (event.target.value.includes(' ')) {
                                    event.target.value = event.target.value.replace(/ /g, '');
                                }
                            }}
                            {...change_password("current_password", { required: t('error_password_empty'), minLength: { value: 6, message: t('error_password_length') } })}

                        />
                        <p className="input_error_msg">{errors.current_password?.message}</p>
                    </div>
                </Form.Group>
                <Form.Group className="form_input_wrap" >
                    <div className="input_wrap">
                        <Form.Label>{t('new_password')}
                            <span className="form_required_input">* {t('required_fill_in')}</span>
                        </Form.Label>
                        <Form.Control
                            type="password"
                            // placeholder={t('new_password')}
                            className=" input_changePassword_newPassword"
                            aria-label="changePassword_newPassword"
                            onKeyDown={(event) => {
                                if (event.key === ' ') {
                                    event.preventDefault();
                                }
                            }}
                            onInput={(event) => {
                                if (event.target.value.includes(' ')) {
                                    event.target.value = event.target.value.replace(/ /g, '');
                                }
                            }}
                            {...change_password("new_password", {
                                required: t('error_password_empty'), minLength: { value: 6, message: t('error_password_length') }, validate: (value) => {
                                    const { current_password } = getValues();
                                    return current_password !== value || 'New Password Cannot Same As Current Password';
                                }
                            })}
                        />
                        <p className="input_error_msg">{errors.new_password?.message}</p>
                    </div>
                </Form.Group>
                <Form.Group className="form_input_wrap" >
                    <div className="input_wrap">
                        <Form.Label>{t('confirm_password')}
                            <span className="form_required_input">* {t('required_fill_in')}</span>
                        </Form.Label>
                        <Form.Control
                            type="password"
                            // placeholder={t('confirm_password')}
                            className=" input_changePassword_confirmPassword"
                            aria-label="changePassword_confirmPassword"
                            onKeyDown={(event) => {
                                if (event.key === ' ') {
                                    event.preventDefault();
                                }
                            }}
                            onInput={(event) => {
                                if (event.target.value.includes(' ')) {
                                    event.target.value = event.target.value.replace(/ /g, '');
                                }
                            }}
                            {...change_password("confirm_password", {
                                required: true, minLength: { value: 6 }, validate: (value) => {
                                    const { new_password } = getValues();
                                    return new_password === value || t('error_confirm_password_matchPassword');
                                }
                            })}
                        />
                        <p className="input_error_msg">{errors.confirm_password?.message}</p>
                    </div>
                </Form.Group>
                <input className="font_button color_button wallet_btnSubmit" type="submit" value={t('submit')} disabled={!isValid} />
            </Form>
            {/* <Alert show={alertShow.show} onHide={onHideAlert} message={alertShow.message} type={alertShow.type} title={alertShow.title} /> */}
            {isLoading && (<Loading />)}
        </>
    );
};

// const ChangeGamePassword = (props) => {
//     const { t } = useTranslation();
//     const [dataLoaded, setDataLoaded] = useState(false);
//     const [providerOptionSelection, setProviderOptionSelection] = useState([]);
//     // const [providerOption, setProviderOption] = useState([]);
//     const [providerName, setProviderName] = useState();

//     // const isDesktopOrLaptop = useMediaQuery({
//     //     query: '(min-width: 992px)'
//     // })

//     const {
//         register: change_game_password,
//         getValues,
//         setValue,
//         // formState,
//         handleSubmit,
//     } = useForm({
//         mode: "onChange",
//     });


//     useEffect(() => {
//         if (!dataLoaded) {
//             const provider_option_selection = [{
//                 provider_option: "918KISS",
//                 name: "Hihi"
//             }, {
//                 provider_option: "MEGA888",
//                 name: "Hihi01"
//             }, {
//                 provider_option: "PUSSY888",
//                 name: "Hihi02"
//             }]

//             setProviderOptionSelection(provider_option_selection);
//             setValue('provider_option', provider_option_selection[0].provider_option);
//             setProviderName(provider_option_selection[0].name);

//             // const method = 'transfer'

//             // query({
//             //   method,
//             //   params: [
//             //     { code: 'username', graphqlType: 'String', required: true, value: username },
//             //   ],
//             //   attributes: []

//             // }).then(({ data }) => {
//             //   console.log('data', data[method])
//             //   setUsernameExist(false)
//             // }).catch((error) => {
//             //   console.log('error: ', error)
//             //   setUsernameExist(true)
//             // })
//             setDataLoaded(true);
//         }

//     }, [dataLoaded, setValue]);

//     const providerOnChange = (event) => {
//         const newValue = JSON.parse(event.target.value);
//         setProviderName(newValue.name)
//     };


//     const onSubmit = () => {
//         const values = getValues();
//         console.log('value: ', values)
//     };


//     return (
//         <>
//             <Form className="changeGamePassword_form" onSubmit={handleSubmit(onSubmit)}>
//                 <Form.Group className="form_input_wrap" >
//                     <div className="input_wrap">
//                         <Form.Label>{t('provider_option')}
//                             <span className="form_required_input">* {t('required_select')}</span>
//                         </Form.Label>
//                         <Form.Select aria-label="providerOption" className="input_providerOption" onChangeCapture={providerOnChange} {...change_game_password("provider_option")}>
//                             {providerOptionSelection.map(function (item, index) {
//                                 return (
//                                     <option value={JSON.stringify(item)} key={index}>{item.provider_option}</option>
//                                 )
//                             })}
//                         </Form.Select>
//                     </div>
//                 </Form.Group>
//                 <Form.Group className="form_input_wrap" >
//                     <div className="input_wrap">
//                         <Form.Label>{t('name')}</Form.Label>
//                         <Form.Control
//                             type="text"
//                             className=" input_providerName"
//                             aria-label="providerName"
//                             value={providerName}
//                             disabled
//                         />
//                     </div>
//                 </Form.Group>
//                 <Form.Group className="form_input_wrap" >
//                     <div className="input_wrap">
//                         <Form.Label>{t('new_password')}
//                             <span className="form_required_input">* {t('required_fill_in')}</span>
//                         </Form.Label>
//                         <Form.Control
//                             type="text"
//                             placeholder={t('new_password')}
//                             className=" input_changePassword_newPassword"
//                             aria-label="changePassword_newPassword"
//                             {...change_game_password("new_password", { required: true, minLength: { value: 6 } })}
//                         />
//                     </div>
//                 </Form.Group>
//                 <Form.Group className="form_input_wrap" >
//                     <div className="input_wrap">
//                         <Form.Label>{t('confirm_password')}
//                             <span className="form_required_input">* {t('required_fill_in')}</span>
//                         </Form.Label>
//                         <Form.Control
//                             type="text"
//                             placeholder={t('confirm_password')}
//                             className=" input_changePassword_confirmPassword"
//                             aria-label="changePassword_confirmPassword"
//                             {...change_game_password("confirm_password", {
//                                 required: true, minLength: { value: 6 }, validate: (value) => {
//                                     const { new_password } = getValues();
//                                     return new_password === value || t('register_error_confirm_password_matchPassword');
//                                 }
//                             })}
//                         />
//                     </div>
//                 </Form.Group>

//                 <input className="font_button color_button wallet_btnSubmit" type="submit" value={t('submit')} />
//             </Form>
//         </>

//     );




// };


const ChangePasswordBody = () => {

    const { t } = useTranslation();

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 992px)'
    })

    return (
        <>
            <div className="changePassword_body">
                {isDesktopOrLaptop ? <>
                    <div className="font_h2 color_primary wallet_container_title">{t('change_friend_transfer_password')}</div>
                    <div className="wallet_container_wrap">

                        {/* <Tabs defaultActiveKey="change_password" id="myAccount_changePasswordTab" className="myAccount_changePasswordTab" >
                            <Tab eventKey="change_password" title={t('change_password')} tabClassName="myAccount_changePasswordTab_selection">
                                <Row>
                                    <Col lg={7} >
                                        <ChangePassword />
                                    </Col>

                                </Row>
                            </Tab>
                             */}
                        {/* <Tab eventKey="change_game_password" title={t('change_game_password')} tabClassName="myAccount_changePasswordTab_selection">
                                <Row>
                                    <Col lg={7} >
                                        <ChangeGamePassword />
                                    </Col>

                                </Row>
                            </Tab> */}
                        {/* </Tabs> */}
                        <Row>
                            <Col lg={7} >
                                <ChangePassword />
                            </Col>

                        </Row>

                    </div>
                </> : <>
                    <Title title={"change_friend_transfer_password"} desktopToggle={false} mobileToggle={true} id={'change_friend_transfer_password'} />
                    <div className="myAccount_wrap">
                        <ChangePassword />
                    </div>
                </>}
            </div>
        </>

    );




};






export default ChangePasswordBody;
