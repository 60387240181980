// import React, { useEffect, useReducer } from "react";
// import { Container, Nav } from "react-bootstrap";
// import { useTranslation } from 'react-i18next';
// import { useMediaQuery } from 'react-responsive';
import PageTitle from '../pageTitle/template_01';
// import PromoContainer from '../../dynamic_section/dynamic_1/template_01';
// import { useMiddletier } from "../../common/middletier";
import "./template_01.css";


// const PROMOTION_ACTION = {
//   SET_DEFAULT_PROMOTIONS: 'SET_DEFAULT_PROMOTIONS',
//   SET_PROMOTIONS: 'SET_PROMOTIONS',
//   SET_PROMOTION_TYPES: 'SET_PROMOTION_TYPES',
//   SET_SELECTED_PROMOTION_TYPE: 'SET_SELECTED_PROMOTION_GAME_TYPE',
// }

// const initialPromotionData = {
//   defaultPromotions: [],
//   promotions: [],
//   promotionTypes: [],
//   selectedPromotionType: 'SHOW_ALL',
// }

// const promotionReducer = (state, action) => {
//   switch (action.type) {

//     case PROMOTION_ACTION.SET_DEFAULT_PROMOTIONS: {
//       const defaultPromotions = action.payload
//       return { ...state, defaultPromotions };
//     }

//     case PROMOTION_ACTION.SET_PROMOTIONS: {
//       const promotions = action.payload
//       return { ...state, promotions };
//     }

//     case PROMOTION_ACTION.SET_PROMOTION_TYPES: {
//       const promotionTypes = [...action.payload]
//       return { ...state, promotionTypes };
//     }

//     case PROMOTION_ACTION.SET_SELECTED_PROMOTION_TYPE: {
//       return { ...state, selectedPromotionType: action.payload };
//     }

//     default: {
//       throw new Error(`Unhandled action type: ${action.type}`)
//     }
//   }
// }

const PromoPage = (props) => {
  const { desktopTitleToggle, mobileTitleToggle } = props;
  // const { query, queries, mutation } = useMiddletier()
  // const [promotionState, promotionDispatch] = useReducer(promotionReducer, initialPromotionData)
  // const { t, i18n } = useTranslation();
  // const isDesktopOrLaptop = useMediaQuery({
  //   query: '(min-width: 992px)'
  // })

  // const onChangePromoType = (newValue) => {
  //   promotionDispatch({ type: PROMOTION_ACTION.SET_SELECTED_PROMOTION_TYPE, payload: newValue })
  // }




  return (
    <section id="promotion_01">
      <div className="promotion_body">
        <PageTitle desktopToggle={desktopTitleToggle} mobileToggle={mobileTitleToggle} title={'promotion'} id={'promotion_title'} />
        {/* <Container>
          <Nav variant="pills" className="promoTab tab_pills_color font_tab_pills" defaultActiveKey={'all'} onSelect={(key) => { onChangePromoType(key) }}>
            {promotionState.promotionTypes?.map(function (item, index) {
              let _item = GAME_MENU_DATA[item] ?? ''
              return (
                <Nav.Item key={`${item}${index}`} >
                  <Nav.Link eventKey={item} className={`promoTab_selection ${item === promotionState.selectedPromotionType ? 'color_active active' : ''}`}>
                    {item === 'SHOW_ALL' ? 'SHOW_ALL' : (t(_item?.name))}
                  </Nav.Link>
                </Nav.Item>
              )
            })}
          </Nav>
          {isDesktopOrLaptop ?
            <PromoContainer desktopToggle={true} mobileToggle={false} imageSize={'1x3'} contentPosition={'right'} actionPosition={'bottom'} id={'promoContainer'} rowDesktop={1} rowMobile={1} numberOfRow={-1} dataType={'promotions'} data={promotionState.promotions} />
            : <PromoContainer desktopToggle={false} mobileToggle={true} imageSize={'1x3'} contentPosition={'bottom'} actionPosition={'bottom'} id={'promoContainer'} rowDesktop={1} rowMobile={1} numberOfRow={-1} dataType={'promotions'} data={promotionState.promotions} />}
        </Container> */}
      </div>
    </section>
  );
}

export default PromoPage;
