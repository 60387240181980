import { Modal } from "react-bootstrap";
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import dayjs from 'dayjs';
import './PromoDetailsDialog.css';
import { useTranslation } from 'react-i18next';


const PromoDetailsDialog = (props) => {
  // const [remainingTime, setRemainingTime] = useState(null);
  const { getdata } = props
  const { t } = useTranslation();


  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)'
  })

  // useEffect(() => {
  //   const lastEffectiveDate = getdata?.last_effective_datetime;
  //   // const displayEndTime = getdata?.display_end_time;


  //   const promotionEndTime = dayjs(`${lastEffectiveDate}T${displayEndTime}`);


  //   const updateCountdown = () => {
  //     const now = dayjs();
  //     const timeRemaining = promotionEndTime.diff(now);
  //     // console.log('promotionEndTime2:', promotionEndTime);
  //     // console.log('now2:', now);
  //     // console.log('timeRemaining2:', timeRemaining);
  //     const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
  //     const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  //     const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
  //     const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

  //     setRemainingTime({ days, hours, minutes, seconds });
  //   };


  //   const intervalId = setInterval(updateCountdown, 1000);


  //   return () => clearInterval(intervalId);
  // }, [getdata?.last_effective_date, getdata?.display_end_time]);
  const RemainingTime = ({ lastEffectiveDate }) => {
    const [remainingTime, setRemainingTime] = useState(null);
    const year = dayjs(lastEffectiveDate).year()
    useEffect(() => {
      // const promotionEndTime = dayjs(`${lastEffectiveDate}T${displayEndTime}`);
      const promotionEndTime = dayjs(`${lastEffectiveDate}`);


      const intervalId = setInterval(() => {
        const now = dayjs();
        const timeRemaining = promotionEndTime.diff(now);

        const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

        setRemainingTime({ days, hours, minutes, seconds });
      }, 1000);

      return () => clearInterval(intervalId);
    }, [lastEffectiveDate]);

    return (
      <div className="w-50">
        {remainingTime && (
          <div className="promoDetails_timeShow">
            <p className="promoDetails_timeShow_title">{t('remaining_time')}</p>

            <div className="promoDetails_timeShow_container">
              {year >= 2999 ? (<>{t('no_expire_date')}</>) : (<><div className="count_container day_count">
                <div className="count_box">
                  <p>{remainingTime?.days}</p>
                  <p>{t('days')}</p>
                </div>
              </div>
                <div className="count_container time_count">
                  <div className="count_box">
                    <p>{remainingTime?.hours}</p>
                    <p>{t('hour')}</p>
                  </div>
                  <span>:</span>
                  <div className="count_box">
                    <p>{remainingTime?.minutes}</p>
                    <p>{t('min')}</p>
                  </div>
                  <span>:</span>
                  <div className="count_box">
                    <p>{remainingTime?.seconds}</p>
                    <p>{t('sec')}</p>
                  </div>
                </div></>)}

            </div>
          </div>
        )}
      </div>
    );
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="promoDetailsModal"
      fullscreen={isDesktopOrLaptop?false:true}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="subTitle">
          {t('promotion_details')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="promoDetails_container">
          <figure>
            {(getdata._desktop_image?.url || getdata._mobile_image?.url) && (
              <img src={`${isDesktopOrLaptop ? (getdata._desktop_image?.url) : (getdata._mobile_image?.url)}`} alt={getdata.title} />
            )}
            <figcaption className="promoDetails_title">{getdata?.title}</figcaption>
            <figcaption className="promoDetails_description">{getdata?.description}</figcaption>
          </figure>
          {/* <div className="promoDetails_title">
            <p>{getdata?.title}</p>
            <p>{getdata?.description}</p>
          </div> */}
          {
            getdata.last_effective_datetime && (
              <RemainingTime lastEffectiveDate={getdata.last_effective_datetime} />
              // <div className="promoDetails_timeShow">
              //   <p className="promoDetails_timeShow_title">Remaining Time</p>
              //   <div className="promoDetails_timeShow_container">
              //     <div className="count_container day_count">
              //       <div className="count_box">
              //         <p>{remainingTime?.days}</p>
              //         <p>Day</p>
              //       </div>
              //     </div>
              //     <div className="count_container time_count">
              //       <div className="count_box">
              //         <p>{remainingTime?.hours}</p>
              //         <p>Hour</p>
              //       </div>
              //       <span>:</span>
              //       <div className="count_box">
              //         <p>{remainingTime?.minutes}</p>
              //         <p>Min</p>
              //       </div>
              //       <span>:</span>
              //       <div className="count_box">
              //         <p>{remainingTime?.seconds}</p>
              //         <p>Sec</p>
              //       </div>
              //     </div>
              //   </div>


              //   {/* <p> Days {remainingTime?.hours} Hours {remainingTime?.minutes} minutes {remainingTime?.seconds} seconds</p> */}
              // </div>
            )
          }

          <div className="promoDetails_content">
            <div dangerouslySetInnerHTML={{ __html: getdata?.content }}></div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default PromoDetailsDialog;
