import { Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { TENANT_BUCKET } from '../common/constants';

import './Loading.css';


const Loading = (props) => {
    //   const { balance, gameProvider, gameUrl } = props
    const { t } = useTranslation();

    const { message, type } = props

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"

            id="loadingPart"
            className={`loading_part ${type==='game'?'game':''}`}
            show={true}
        >
            {message && (<div className="loading_msg">
                {/* <div className="messageTitle">{t('reminder')}:</div> */}
                <div>{message}</div>
            </div>)}
            <div className="loading_img"><img src={`${TENANT_BUCKET}/icon/loading.gif`} alt="" /></div>
        </Modal>
    );
}

export default Loading;
