import React, { useEffect, useRef, useReducer, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useTranslation, Trans } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
// import { useHistory } from "react-router-dom";
import { useMiddletier } from "../../common/middletier";
import "./template_01.css";
import { TENANT_BUCKET } from '../../common/constants';
// import { useState } from "react/cjs/react.production.min";
import { useStoreDispatch, useStoreState, APP_STORE_ACTION } from '../../common/storeContext';

const TITLE_ACTION = {
    SET_TITLE: 'SET_TITLE',
    SET_SELECTED_TITLE: 'SET_SELECTED_TITLE'
}

const initialTitleData = {
    title: [],
    selectedTitle: '',
}

const titleReducer = (state, action) => {
    switch (action.type) {

        case TITLE_ACTION.SET_TITLE: {
            const title = action.payload
            return { ...state, title };
        }


        case TITLE_ACTION.SET_SELECTED_TITLE: {
            const selectedTitle = action.payload
            return { ...state, selectedTitle };
        }


        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

const Title = (props) => {
    const { desktopToggle, mobileToggle, id, disabledBackButton, onlyBackButton, desktopHeadingHide, mobileHeadingHide, dropdown, backgroundImg } = props;
    const { t, i18n } = useTranslation();
    const location = useLocation()
    const { query } = useMiddletier();
    const navigate = useNavigate();
    const [titleState, titleDispatch] = useReducer(titleReducer, initialTitleData)
    const [isLoading, setLoading] = useState(false);
    const appState = useStoreState()

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 992px)'
    })
    // const { titleDropDown, setTitleDropDown } = useState([])

    const backgroundImageTitle = {
        backgroundImage: backgroundImg ? `url(${TENANT_BUCKET}/background/bg_title.png)` : 'auto'
    }


    const onChangeTitle = (newValue) => {
        if (newValue === "contact_us") {
            navigate(`/contact-us`);
        } else if(newValue === "coupon"){
            navigate(`/coupon`);
        }else {
            navigate(`/help#${newValue}`);
        }
    }


    useEffect(() => {

        if (dropdown) {
            setLoading(true)

            query({
                method: 'getFaq',
                params: [
                    { code: 'language', graphqlType: 'String', required: true, value: i18n.resolvedLanguage },
                    { code: 'category', graphqlType: 'String', required: false, value: 'HELP' }
                ],
                attributes: []
            })
                .then(({ data }) => {
                    setLoading(false)
                    // setTitleDropDown(data['getFaq'])
                    let getAllTitle = data['getFaq'].map(item => item.faq)
                    getAllTitle.push('contact_us')
                    if (appState.loggedIn) { getAllTitle.push('coupon') }
                    titleDispatch({ type: TITLE_ACTION.SET_TITLE, payload: getAllTitle })

                }).catch((error) => {
                    setLoading(false)
                    console.log('error: ', error)
                })

        }

    }, [query, i18n.resolvedLanguage, dropdown,appState.loggedIn]);

    useEffect(() => {
        // setSelectedKey(location.hash)
        let getLocation = ''
        if (location.pathname === '/help') {
            getLocation = location.hash ? location.hash?.substring(1) : (titleState.title?.length > 0 && (`${titleState.title[0]}`))
        }

        if (location.pathname === '/contact-us') {
            getLocation = 'contact_us'
        }

        if (location.pathname === '/coupon') {
            getLocation = 'coupon'
        }
        titleDispatch({ type: TITLE_ACTION.SET_SELECTED_TITLE, payload: getLocation })

    }, [location, titleState.title])

    if ((desktopToggle || mobileToggle) === true) {
        return (
            <>
                <section style={backgroundImageTitle} className={`section pageTitle font_h2 color_primary ${dropdown ? 'dropdownTitle' : ''} ${isDesktopOrLaptop ? 'desktop' : 'mobile'} ${desktopToggle ? "" : "d-lg-none"} ${mobileToggle ? "" : "d-none d-lg-block"}`} id={`${id}`}>
                    {!onlyBackButton ?
                        <>
                            {(desktopHeadingHide && mobileHeadingHide) ? <></> :
                                <Container className={` title_container  ${!desktopHeadingHide ? "" : "d-lg-none"} ${!mobileHeadingHide ? "" : "d-none d-lg-block"}`}>
                                    <Link className={` ${disabledBackButton && 'invisible'}`} onClick={() => navigate(-1)}><BiArrowBack /></Link>
                                    {dropdown ?
                                        <div>
                                            <Form.Select value={titleState.selectedTitle} onChange={(evt) => { onChangeTitle(evt.target.value) }}>
                                                {titleState.title?.map(function (item, index) {
                                                    console.log('item', item)
                                                    return (
                                                        <option value={item} key={item}>{t(item)}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </div>
                                        : <div>{t(props.title)}</div>}
                                </Container>
                            }
                            {props.desc &&
                                <div className="container desc_container font_h4 d-none d-lg-block">
                                    <Trans i18nKey={props.desc} components={{ tncLink: <a href="/help#term-and-conditions">tnc</a>, faqLink: <a href="/help#faq">FAQ</a> }} />
                                </div>
                            }
                        </> :
                        <>
                            <Container className="font_h2 title_container  title_back">
                                <Link className="color_text" onClick={() => navigate(-1)}><BiArrowBack className="me-2" />{t('back')}</Link>

                            </Container>

                        </>
                    }


                </section>

            </>

        );
    }

};



export default Title;