import React, { useEffect, useState } from "react";
import { Tab, Tabs, Row, Col, Form } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import Title from '../../../pageTitle/default.js';
import { useForm } from "react-hook-form";

const ChangeGamePassword = (props) => {
    const { t } = useTranslation();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [providerOptionSelection, setProviderOptionSelection] = useState([]);
    const [providerOption, setProviderOption] = useState([]);
    const [providerName, setProviderName] = useState();

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 992px)'
    })

    const {
        register: change_game_password,
        getValues,
        setValue,
        formState,
        handleSubmit,
    } = useForm({
        mode: "onChange",
    });


    useEffect(() => {
        if (!dataLoaded) {
            const provider_option_selection = [{
                provider_option: "918KISS",
                name: "Hihi"
            }, {
                provider_option: "MEGA888",
                name: "Hihi01"
            }, {
                provider_option: "PUSSY888",
                name: "Hihi02"
            }]

            setProviderOptionSelection(provider_option_selection);
            setValue('provider_option', provider_option_selection[0].provider_option);
            setProviderName(provider_option_selection[0].name);

            // const method = 'transfer'

            // query({
            //   method,
            //   params: [
            //     { code: 'username', graphqlType: 'String', required: true, value: username },
            //   ],
            //   attributes: []

            // }).then(({ data }) => {
            //   console.log('data', data[method])
            //   setUsernameExist(false)
            // }).catch((error) => {
            //   console.log('error: ', error)
            //   setUsernameExist(true)
            // })
            setDataLoaded(true);
        }

    }, [dataLoaded, setValue]);

    const providerOnChange = (event) => {
        const newValue = JSON.parse(event.target.value);
        setProviderName(newValue.name)
    };


    const onSubmit = () => {
        const values = getValues();
        console.log('value: ', values)
    };

    return (
        <>
            <div className="changeGamePassword_body">
                <>
                    <Title title={"change_game_password"} desktopToggle={false} mobileToggle={true} id={'changeGamePassword_title'} />
                    <div className="myAccount_wrap">
                        <Form className="changeGamePassword_form" onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group className="mb-3" >
                                <Form.Label>{t('provider_option')}
                                    <span className="form_required_input">* {t('required_select')}</span>
                                </Form.Label>
                                <Form.Select aria-label="providerOption" className="input_providerOption" onChangeCapture={providerOnChange} {...change_game_password("provider_option")}>
                                    {providerOptionSelection.map(function (item, index) {
                                        return (
                                            <option value={JSON.stringify(item)} key={index}>{item.provider_option}</option>
                                        )
                                    })}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3 " >
                                <Form.Label>{t('name')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    className=" input_providerName"
                                    aria-label="providerName"
                                    value={providerName}
                                    disabled
                                />
                            </Form.Group>
                            <Form.Group className="mb-3 " >
                                <Form.Label>{t('new_password')}
                                    <span className="form_required_input">* {t('required_fill_in')}</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('new_password')}
                                    className=" input_changePassword_newPassword"
                                    aria-label="changePassword_newPassword"
                                    {...change_game_password("new_password", { required: true, minLength: { value: 6 } })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3 " >
                                <Form.Label>{t('confirm_password')}
                                    <span className="form_required_input">* {t('required_fill_in')}</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('confirm_password')}
                                    className=" input_changePassword_confirmPassword"
                                    aria-label="changePassword_confirmPassword"
                                    {...change_game_password("confirm_password", {
                                        required: true, minLength: { value: 6 }, validate: (value) => {
                                            const { new_password } = getValues();
                                            return new_password === value || t('register_error_confirm_password_matchPassword');
                                        }
                                    })}
                                />
                            </Form.Group>

                            <input className="font_button color_button wallet_btnSubmit" type="submit" value={t('submit')}/>
                      
                     
                        </Form>
                    </div>
                </>
            </div>
        </>

    );

};


export default ChangeGamePassword;
