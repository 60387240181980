import React, { useState, useEffect, useReducer, useRef ,useLayoutEffect} from "react";
import { useStoreState, useStoreDispatch, APP_STORE_ACTION } from '../common/storeContext';
import { Modal, Nav } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { BsExclamationCircleFill } from "react-icons/bs"
import { useMiddletier } from "../common/middletier";
import { TENANT_BUCKET } from '../common/constants'
import { useNavigate, Link } from "react-router-dom";
import './FloatingIcon.css';
import { AiFillCloseCircle } from "react-icons/ai";
import Draggable from 'react-draggable';
import { useMediaQuery } from 'react-responsive';
import { useMeasure } from 'react-use';


const CLAIM_ACTION = {
    SET_SHOW_DATA: 'SET_SHOW_DATA',
    SET_FILTER_TYPES: 'SET_FILTER_TYPES',
    SET_SELECTED_FILTER_TYPE: 'SET_SELECTED_FILTER_TYPE',
}

const initialClaimData = {
    showData: [],
    filterTypes: [{ code: 'PROMOTION', name: 'promo_claim' }, { code: 'COUPON', name: 'coupon_claim' }],
    selectedFilterType: { code: 'PROMOTION', name: 'promo_claim' },
}

const claimReducer = (state, action) => {
    switch (action.type) {

        case CLAIM_ACTION.SET_SHOW_DATA: {
            const showData = action.payload
            return { ...state, showData };
        }

        case CLAIM_ACTION.SET_FILTER_TYPES: {
            const filterTypes = [...action.payload]
            return { ...state, filterTypes };
        }

        case CLAIM_ACTION.SET_SELECTED_FILTER_TYPE: {
            const selectedFilterType = state.filterTypes.find((filterTypes) => filterTypes.code === action.payload)
            return { ...state, selectedFilterType };
        }

        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

const calculatePositions = (width, height, bottomMargin, middleMargin, rightMargin, elementWidth, elementHeight) => {
    return {
        'TOP_LEFT': {
            x: 0,
            y: 0,
        },
        'MIDDLE_LEFT': {
            x: 0,
            y: Math.max(0, Math.min(height - elementHeight, (height / 2) - (elementHeight / 2))),
        },
        'BOTTOM_LEFT': {
            x: 0,
            y: Math.max(0, height - bottomMargin - elementHeight),
        },
        'TOP_RIGHT': {
            x: Math.max(0, width - rightMargin - elementWidth),
            y: 0,
        },
        'MIDDLE_RIGHT': {
            x: Math.max(0, width - rightMargin - elementWidth),
            y: Math.max(0, Math.min(height - elementHeight, (height / 2) - (elementHeight / 2))),
            // y: Math.max(0, height - bottomMargin - elementHeight),
            
        },
        'BOTTOM_RIGHT': {
            x: Math.max(0, width - rightMargin - elementWidth),
            y: Math.max(0, height - bottomMargin - elementHeight),
            // y: Math.max(0, Math.min(height - elementHeight, (height / 2) - (elementHeight / 2))),
        }
    };
};

const FloatingIcon = (props) => {
    const { enableMove, enableClose, position, url, desktopImageUrl, mobileImageUrl } = props
    const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 });
    const [closeFloating, setCloseFloating] = useState(false);
    // const [forceRerenderKey, setForceRerenderKey] = useState(0);
    const [isReady, setIsReady] = useState(false);
    const [dragging, setDragging] = useState(false);
    const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
    const [activeDrags, setActiveDrags] = useState(0);
    const [elementWidth, setElementWidth] = useState(0);
    const [ref, { width, height }] = useMeasure();
    const [positionFloating, setPositionFloating] = useState(null);

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 992px)'
    })
    // const ref = useRef(null)
 
    useEffect(() => {

        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        const positions = calculatePositions(screenWidth, screenHeight, 100, 130, !isDesktopOrLaptop ? 25 : 35,width,height)
        const getPosition = positions[position]
        // console.log('getHeight', screenHeight)
        // console.log('getWidth', screenWidth)
        // console.log('getElementWidth', width)
        // console.log('getElementheight', height)


        // console.log('getPosition', getPosition)
     
        setInitialPosition(getPosition)
        
        if(width>0){
            setPositionFloating(getPosition)
           
        }
        setIsReady(true)
      
    }, [isDesktopOrLaptop, position,width,height])

    // const handleStop = (e, data) => { 
    //      setPosition({ x: data.x, y: data.y });
    //   };

    const handleImageDragStart = (event) => {
        event.preventDefault();
    };

    const onCloseFloating = (event) => {
        // event.preventDefault();
        // event.stopPropagation();
        if (!dragging) {
            setCloseFloating(true)
        }
        setDragging(false);
    }

    const onClickUrl = () => {
        if (!dragging) {
            if(url){
                window.open(url, '_blank', 'noopener,noreferrer');
            }
        }
        setDragging(false);
    }

    const onStart = (event, data) => {
        setPositionFloating(null)

        setDragging(false);
        setStartPosition({ x: data.x, y: data.y });
        setActiveDrags((prevActiveDrags) => prevActiveDrags + 1);
    };

    const onDrag = (e, data) => {
        const deltaX = Math.abs(data.x - startPosition.x);
        const deltaY = Math.abs(data.y - startPosition.y);

        if (deltaX > 5 || deltaY > 5) {
            setDragging(true);
        }
    };

    const onStop = (event, data) => {
        setActiveDrags((prevActiveDrags) => prevActiveDrags - 1);
    };


    if (!isReady) {
        return null
    }
    return (
        <>
            {/* {isDesktopOrLaptop ? */}
            <Draggable
                axis="both"
                handle=".handle"
                defaultPosition={initialPosition}
                position={positionFloating}
                //   grid={[25, 25]}
                scale={1}
                disabled={!enableMove}
                onStart={onStart}
                onDrag={onDrag}
                onStop={onStop}
                // ref={ref}
            // key={forceRerenderKey}
            // nodeRef={React.createRef()}
            >
                <div className={`handle floatingIcon_body ${closeFloating && ('d-none')}`} ref={ref} >
                    {enableClose && (<AiFillCloseCircle className="floatingIcon_closeButton" onTouchEnd={(e) => onCloseFloating(e)} onClick={onCloseFloating} />)}
                    <div onTouchEnd={(e) => onClickUrl(e)} onClick={onClickUrl} >
                        <img src={`${isDesktopOrLaptop ? desktopImageUrl : mobileImageUrl}`} alt="floating img" onDragStart={handleImageDragStart} />
                    </div>
                    {/* </div> */}
                </div>
            </Draggable>
            {/* // :
                // <div className={`floatingIcon_body ${closeFloating && ('d-none')} desktop_floating`} style={{ transform: `translate(${initialPosition?.x}px, ${initialPosition?.y}px)` }}>
                //     <AiOutlineCloseCircle className="floatingIcon_closeButton" onClick={onCloseFloating} />
                //     <Link to={`${url}`} target="_blank">
                //         <img src={`${imageUrl}`} alt="floating img" />
                //     </Link>
                // </div>
            // } */}
        </>
    );
}

export default FloatingIcon;
