import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation } from "swiper";
// import { TENANT_BUCKET } from '../../common/constants'
import { useMediaQuery } from 'react-responsive';
import { useMiddletier } from "../../common/middletier";
import ImageWithPlaceholder from '../../components/ImageWithPlaceholder'
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./template_01.css";

const Banner = (props) => {
  const { i18n } = useTranslation();
  const { navigation, speed, pagination } = props;
  const [banners, setBanners] = useState([]);
  const { query } = useMiddletier();
  const paginations = {
    clickable: true,
  };
  const speeds = speed ? speed * 1000 : 3000;

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)'
  })

  useEffect(() => {
    const method = 'getBanners'

    query({
      method,
      params: [
        { code: 'language', graphqlType: 'String', required: true, value: i18n.resolvedLanguage },
        { code: 'website', graphqlType: 'String', required: false, value: 'MEMBER' },

      ],
      attributes: []

    }).then(({ data }) => {
      // console.log('getBanners', data[method])
      setBanners(data[method]);

    }).catch((error) => {
      // console.log('error: ', error)
    })

  }, [query, i18n.resolvedLanguage]);

  return (
    <>
      {banners && (
        <Swiper
          centeredSlides={true}
          autoplay={{
            delay: speeds,
            disableOnInteraction: false,
          }}
          pagination={pagination ? paginations : false}
          navigation={navigation ?? false}

          modules={[Autoplay, Pagination, Navigation]}
          className="bannerswiper section"
          id="banner_01"
        >
          {banners.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <ImageWithPlaceholder src={`${isDesktopOrLaptop ? item.desktop_image_url : item.mobile_image_url}`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/1x3.png`} alt={`main banner_${index}`} /> 
              </SwiperSlide>
            )
          })}

        </Swiper>
      )}

    </>
  );
};

export default Banner;
