import React, { useEffect, useState, useReducer, useRef } from "react";
import { useStoreState, APP_STORE_ACTION, useStoreDispatch } from '../../common/storeContext'
// import { Stepper, Step } from "react-form-stepper";
import StepWizard from "react-step-wizard";
import { Container, Form, Modal } from "react-bootstrap";
import { useTranslation, Trans } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import PageTitle from '../pageTitle/template_01';
import { useForm } from "react-hook-form";
import { useMiddletier } from "../../common/middletier";
import { TENANT_BUCKET } from '../../common/constants';
import { Navigate } from 'react-router-dom';
import Loading from '../../components/Loading';
import './template_01.css';
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { useLocation } from 'react-router-dom';
import { IoIosArrowDown } from "react-icons/io";



const REGISTER_ACTION = {
  SET_EXIST_STATUS: 'SET_EXIST_STATUS',
  SET_SELECTED_VERIFY_TYPE: 'SET_SELECTED_VERIFY_TYPE',
  SET_VERIFY_ID: 'SET_VERIFY_ID',
  SET_VERIFY_CODE: 'SET_VERIFY_CODE',
  SET_CODE_VALIDATION_RESULT: 'SET_CODE_VALIDATION_RESULT',
  SET_COUNTRY_DAILING_CODE: 'SET_COUNTRY_DAILING_CODE',
  SET_SELECTED_COUNTRY_DAILING_CODE: 'SET_SELECTED_COUNTRY_DAILING_CODE'

}

const initialRegisterData = {
  usernameExist: false,
  phoneNumberExist: false,
  emailExist: false,
  phoneNumberError: false,
  emailError: false,
  selectedVerifyType: '',
  verifyID_SMS: '',
  verifyCode_SMS: '',
  verifyID_EMAIL: '',
  verifyCode_EMAIL: '',
  codeValidationResult: true,
  countryDailingCode: [],
  selectedCountryDailingCode: ''
}

const registerReducer = (state, action) => {
  switch (action.type) {

    case REGISTER_ACTION.SET_EXIST_STATUS: {
      const { field, value } = action.payload;
      return { ...state, [field]: value ?? false };
    }

    case REGISTER_ACTION.SET_SELECTED_VERIFY_TYPE: {
      return { ...state, selectedVerifyType: action.payload };
    }

    case REGISTER_ACTION.SET_VERIFY_ID: {
      const { field, value } = action.payload;
      return { ...state, [field]: value };
    }

    case REGISTER_ACTION.SET_VERIFY_CODE: {
      const { field, value } = action.payload;
      return { ...state, [field]: value };
    }

    case REGISTER_ACTION.SET_CODE_VALIDATION_RESULT: {
      return { ...state, codeValidationResult: action.payload };
    }

    case REGISTER_ACTION.SET_COUNTRY_DAILING_CODE: {
      const countryDailingCode = action.payload
      return { ...state, countryDailingCode, selectedCountryDailingCode: countryDailingCode?.length > 0 ? countryDailingCode[0] : '60' };
    }

    case REGISTER_ACTION.SET_SELECTED_COUNTRY_DAILING_CODE: {
      return { ...state, selectedCountryDailingCode: action.payload };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

// VALIDATION RULES
const getValidationRules = (type, t, getValues, appState) => {
  switch (type) {
    case 'username':
      return {
        required: t('error_username_empty'),
        minLength: { value: 6, message: t('error_username_length') },
        maxLength: { value: 12, message: t('error_username_length') },
      };
    case 'password':
      return {
        required: t('error_password_empty'),
        minLength: { value: 6, message: t('error_password_length') },
      };
    case 'confirm_password':
      return {
        required: t('error_confirm_password_empty'),
        minLength: { value: 6, message: t('error_confirm_password_length') },
        validate: (value) => {
          const { password } = getValues();
          return (
            password === value || t('error_confirm_password_matchPassword')
          );
        },
      };
    case 'affiliate':
      return {
        required: false,
        minLength: { value: 6, message: 'Affiliate min length 6' },
        maxLength: { value: 6, message: 'Affiliate max length 6' },
      };
    case 'agreement':
      return {
        required: true,
      };
    case 'fullname':
      return {
        required: t('error_fullname_empty'),
        pattern: { value: /^[a-zA-Z\s/@]*$/, message: t('error_fullname_format') },
      };
    case 'email':
      return {
        required: appState.registration.emailRequired && t('error_email_empty'),
        pattern: { value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, message: t('error_email_format') },
      };
    case 'code':
      return {
        required: t('error_code_empty'),
      };
    case 'phone_number':
      return {
        required: appState.registration.phoneRequired && t('error_phone_number_empty'),
        // pattern: { value: /^\(?(1[0-9]{1})\)?[-. ]?([0-9]{7,8})$/, message: t('error_phone_number_format') },
        pattern: { value: /^\d{8,}$/, message: t('error_phone_number_format') }
      };
    default:
      return {};
  }
};

// STEP 1
const One = (props) => {
  const [registerState, registerDispatch] = useReducer(registerReducer, initialRegisterData)
  const {
    register,
    setValue,
    getValues,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: "onChange",
  });
  const { t } = useTranslation();
  const { query } = useMiddletier()
  const [disabledNextButton, setDisabledNextButton] = useState(true)
  const [disabledAffiliate, setDisabledAffiliate] = useState(false)
  const [showAffInput, setShowAffInput] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const location = useLocation()
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)'
  })

  // WHEN FOCUS USERNAME INPUT
  const onFocusUsername = () => {
    registerDispatch({
      type: REGISTER_ACTION.SET_EXIST_STATUS,
      payload: { field: 'usernameExist', value: false },
    });
  }

  // CHECK IF USERNAME EXIST
  const checkUsername = () => {
    const { username } = getValues();
    if (username?.length > 0) {
      const method = 'checkUsername'

      query({
        method,
        params: [
          { code: 'username', graphqlType: 'String', required: true, value: username },
        ],
        attributes: []

      }).then(({ data }) => {
        registerDispatch({
          type: REGISTER_ACTION.SET_EXIST_STATUS,
          payload: { field: 'usernameExist', value: false },
        });
      }).catch((error) => {
        console.log('error: ', error)
        registerDispatch({
          type: REGISTER_ACTION.SET_EXIST_STATUS,
          payload: { field: 'usernameExist', value: true },
        });
      })
    }

  }

  // STEP 1 SUBMIT
  const onSubmit = () => {
    const { username } = getValues();
    const method = 'checkUsername'
    setLoading(true)
    query({
      method,
      params: [
        { code: 'username', graphqlType: 'String', required: true, value: username },
      ],
      attributes: []

    }).then(({ data }) => {
      setLoading(false)
      const values = getValues();
      props.userCallback(values);
      props.nextStep();
    }).catch((error) => {
      console.log('error: ', error)
      setLoading(false)
      registerDispatch({
        type: REGISTER_ACTION.SET_EXIST_STATUS,
        payload: { field: 'usernameExist', value: true },
      });
    })


  };

  useEffect(() => {
    if (isValid && !registerState.usernameExist) {
      setDisabledNextButton(false)
    }
    else {
      setDisabledNextButton(true)
    }

  }, [isValid, registerState.usernameExist])



  useEffect(() => {
    // const getAffiliate = new URLSearchParams(location.search).get('affiliate') ?? '';
    const getLastAffiliate = localStorage.getItem('aff_id');

    // if (getAffiliate !== '') {
    //   localStorage.setItem("aff_id", getAffiliate);
    //   setValue("affiliate", getAffiliate);
    // } else {

    // }
    if (getLastAffiliate !== null) {
      const checkAffiliate = getLastAffiliate?.length >= 6 ? getLastAffiliate?.substring(0, 6) : getLastAffiliate?.padEnd(6, '0');
      setValue("affiliate", checkAffiliate);
      setDisabledAffiliate(true)
      setShowAffInput(true)
    }
  }, [location, setValue])


  return (
    <>
      <Form className="register_form_step1" onSubmit={handleSubmit(onSubmit)} autoComplete="off">

        {/* USERNAME */}
        <Form.Group className="form_field form_field_username" >
          <Form.Label>{t('username')} <div className="form_required">*</div></Form.Label>
          <div className="form_field_input">
            <Form.Control
              type="text"
              name="username"
              placeholder={t('username')}
              className="input_username"
              aria-label="Username"
              onFocus={onFocusUsername}
              onBlurCapture={() => checkUsername()}
              autoComplete="off"
              onKeyDown={(event) => {
                if (event.key === ' ') {
                  event.preventDefault();
                }
              }}
              onInput={(event) => {
                if (event.target.value.includes(' ')) {
                  event.target.value = event.target.value.replace(/ /g, '');
                }
                event.target.value = event.target.value.replace(/[^a-zA-Z0-9]/g, '');
              }}
              {...register("username", getValidationRules('username', t))}
            />
            <p className="register_error_msg">{errors.username?.message}</p>
            {(registerState.usernameExist && !errors.username) && <p className="register_error_msg">{t('error_username_exist')}</p>}

            {/* {
              (() => {
               
                const { username } = getValues();
                if (!registerState.usernameExist && !errors.username) {
                  return (
                    <p className="register_error_msg">
                      {t('error_username_exist')}
                    </p>
                  );
                }
                return null;
              })()
            } */}
          </div>
        </Form.Group>

        {/* PASSWORD */}
        <Form.Group className="form_field" >
          <Form.Label>{t('password')}<div className="form_required">*</div></Form.Label>
          <div className="form_field_input">
            <Form.Control
              type="password"
              name="password"
              placeholder={t('password')}
              className="input_password"
              aria-label="Password"
              autoComplete="off"
              onKeyDown={(event) => {
                if (event.key === ' ') {
                  event.preventDefault();
                }
              }}
              onInput={(event) => {
                if (event.target.value.includes(' ')) {
                  event.target.value = event.target.value.replace(/ /g, '');
                }
              }}
              {...register("password", getValidationRules('password', t))}
            />
            <p className="register_error_msg">{errors.password?.message}</p>
          </div>
        </Form.Group>

        {/* CONFIRM PASSWORD */}
        <Form.Group className="form_field" >
          <Form.Label>{t('confirm_password')}<div className="form_required">*</div></Form.Label>
          <div className="form_field_input">
            <Form.Control
              type="password"
              name="confirm_password"
              placeholder={t('confirm_password')}
              className="input_confirm_password"
              aria-label="Confirm Password"
              autoComplete="off"
              onKeyDown={(event) => {
                if (event.key === ' ') {
                  event.preventDefault();
                }
              }}
              onInput={(event) => {
                if (event.target.value.includes(' ')) {
                  event.target.value = event.target.value.replace(/ /g, '');
                }
              }}
              {...register("confirm_password", getValidationRules('confirm_password', t, getValues))}
            />
            <p className="register_error_msg">{errors.confirm_password?.message}</p>
          </div>
        </Form.Group>

        <hr className="register_form_line" />

        {/* AFFILIATE */}
        <Form.Group className="form_field" >
          <Form.Label>{t('affiliate')}<div className={`toggleAffInput ${showAffInput && 'showInput'} ${isDesktopOrLaptop && ('d-none')}`} onClick={() => setShowAffInput(!showAffInput)}><IoIosArrowDown /></div></Form.Label>
          {((showAffInput && !isDesktopOrLaptop) || isDesktopOrLaptop) && (<div className="form_field_input">
            <Form.Control
              type="text"
              name="affiliate"
              placeholder={t('affiliate')}
              className="input_affiliate"
              autoComplete="off"
              aria-label="Affiliate"
              maxLength={6}
              disabled={disabledAffiliate}
              onKeyDown={(event) => {
                if (event.key === ' ') {
                  event.preventDefault();
                }
              }}
              onInput={(event) => {
                if (event.target.value.includes(' ')) {
                  event.target.value = event.target.value.replace(/ /g, '');
                }

                if (event.target.value.length > 6) {
                  event.target.value = event.target.value.slice(0, 6);
                }
              }}

              {...register("affiliate", getValidationRules('affiliate', t))}
            />
            <p className="register_error_msg">{errors.affiliate?.message}</p>
          </div>)}
        </Form.Group>

        {/* AGREEMENT */}
        <Form.Group className="form_field" >
          <Form.Label></Form.Label>
          <div className="form_field_input">
            <Form.Check type='checkbox' >
              <Form.Check.Input type='checkbox' defaultChecked {...register("agreement", { required: true })} />
              <Form.Check.Label>  <Trans i18nKey={'register_agreement'} components={{ tncLink: <a href="/help#term-and-conditions">tnc</a> }} /></Form.Check.Label>
            </Form.Check>
          </div>
        </Form.Group>

        {/* MOBILE REMINDER */}
        {!isDesktopOrLaptop && (<div className="register_form_reminder">
          <div className="register_form_reminder_title">
            {t('reminder')}
          </div>
          <div className="register_form_reminder_content">
            <span>{t('register_reminder')}</span>
            <span>{t('register_reminder_2')}</span>
          </div>
        </div>)}

        {/* NEXT BUTTON */}
        <Form.Group className="form_field" >
          <Form.Label></Form.Label>
          <div className="form_field_input input_submit_wrap">
            <input type='submit' className="font_button color_button register_btnNext" value={t('next')} disabled={disabledNextButton} />
          </div>
        </Form.Group>
      </Form>

      <hr className="register_form_line" />

      {/* DESKTOP REMINDER */}
      {isDesktopOrLaptop && <div className="register_form_reminder">
        <div className="register_form_reminder_title">
          {t('reminder')}
        </div>
        <div className="register_form_reminder_content">
          <span>{t('register_reminder')}</span>
          <span>{t('register_reminder_2')}</span>
        </div>
      </div>}

    </>
  );
};

// STEP 2
const Two = (props) => {
  const appState = useStoreState()
  const { t } = useTranslation();
  const { query, mutation } = useMiddletier()
  const [disabledPhoneBtn, setdisabledPhoneBtn] = useState(false);
  const [disabledEmailBtn, setdisabledEmailBtn] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [verifyDialogShow, setverifyDialogShow] = useState(false);
  const isProcessing = useRef(false);
  // const [phoneValue, setPhoneValue] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [isLoadingOTP, setLoadingOTP] = useState(false);

  // verification code modal
  const [timeExpires, setTimeExpires] = useState(+`${appState.registration.otpPinExpiresIn}`);
  const [send, setSend] = useState(false);
  const minutes = Math.floor(timeExpires / 60000);
  const seconds = Math.floor((timeExpires % 60000) / 1000);
  const [registerState, registerDispatch] = useReducer(registerReducer, initialRegisterData)
  const appDispatch = useStoreDispatch()
  const [visitorId, SetVisitorId] = useState()



  const {
    register: verifyCode,
    getValues: getCodeValue,
    formState: { errors: errorsCode },
    // handleSubmit: handleSubmitCode,
  } = useForm({
    mode: "onChange",
  });

  const handleBack = () => {
    props.previousStep();
  };

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)'
  })

  const {
    register,
    getValues,
    formState: { errors, isValid, dirtyFields },
    handleSubmit,

  } = useForm({
    mode: "onChange",
  });



  const onChangeCountryDailingCode = (code) => {
    registerDispatch({ type: REGISTER_ACTION.SET_SELECTED_COUNTRY_DAILING_CODE, payload: code })
  }


  // WHEN FOCUS PHONE NUMBER INPUT
  const onFocusPhoneNumber = () => {
    registerDispatch({
      type: REGISTER_ACTION.SET_EXIST_STATUS,
      payload: { field: 'phoneNumberExist', value: false },
    });

    registerDispatch({
      type: REGISTER_ACTION.SET_EXIST_STATUS,
      payload: { field: 'phoneNumberError', value: false },
    });
    setdisabledPhoneBtn(false)
  }

  // WHEN FOCUS EMAIL INPUT
  const onFocusEmail = () => {
    registerDispatch({
      type: REGISTER_ACTION.SET_EXIST_STATUS,
      payload: { field: 'emailExist', value: false },
    });

    registerDispatch({
      type: REGISTER_ACTION.SET_EXIST_STATUS,
      payload: { field: 'emailError', value: false },
    });
    setdisabledEmailBtn(false)

  }

  // WHEN FOCUS VERIFICATION CODE INPUT
  const onFocusVerificationCode = () => {
    registerDispatch({ type: REGISTER_ACTION.SET_CODE_VALIDATION_RESULT, payload: true })
  }

  // CHECK IF EMAIL / PHONENUMBER (CONTACT) EXIST
  const checkContact = (type) => {
    const { email, phone_number, currency } = getValues();
    let checkFormatPhoneNumber = phone_number.replace(/^0+/, '');
    // console.log('checkFormatPhoneNumber', checkFormatPhoneNumber)
    const valueMap = {
      EMAIL: email,
      SMS: checkFormatPhoneNumber,
    };
    const method = (type === 'EMAIL' ? 'checkEmail' : 'checkPhoneNumber')
    // const checkCurrency = !currency ? appState.registration.countryDailingCode[0] : currency
    const checkCurrency = registerState.selectedCountryDailingCode || appState.registration.defaultCountryDailingCode
    if (valueMap[type]?.length > 0) {
      query({
        method,
        params: [
          { code: type === 'EMAIL' ? 'email' : 'phone_number', graphqlType: 'String', required: true, value: type === 'EMAIL' ? email : `${checkCurrency}${checkFormatPhoneNumber}` },
        ],
        attributes: []

      }).then(({ data }) => {
        if (type === 'EMAIL') {
          // setEmailExist(false);
          setdisabledEmailBtn(false);
          registerDispatch({
            type: REGISTER_ACTION.SET_EXIST_STATUS,
            payload: { field: 'emailError', value: false },
          });
          registerDispatch({
            type: REGISTER_ACTION.SET_EXIST_STATUS,
            payload: { field: 'emailExist', value: false },
          });

        } else {
          // setPhonenumberExist(false);
          setdisabledPhoneBtn(false);
          registerDispatch({
            type: REGISTER_ACTION.SET_EXIST_STATUS,
            payload: { field: 'phoneNumberExist', value: false },
          });
          registerDispatch({
            type: REGISTER_ACTION.SET_EXIST_STATUS,
            payload: { field: 'phoneNumberError', value: false },
          });
        }
        onClickVerify(type)
      }).catch((error) => {
        console.log('error: ', error)
        if (type === 'EMAIL') {
          // setEmailExist(true);
          setdisabledEmailBtn(true);
          if (error.toString().includes("Invalid")) {
            registerDispatch({
              type: REGISTER_ACTION.SET_EXIST_STATUS,
              payload: { field: 'emailError', value: true },
            });
          } else {
            registerDispatch({
              type: REGISTER_ACTION.SET_EXIST_STATUS,
              payload: { field: 'emailExist', value: true },
            });
          }

        } else {
          // setPhonenumberExist(true);
          setdisabledPhoneBtn(true);
          if (error.toString().includes("Invalid")) {
            registerDispatch({
              type: REGISTER_ACTION.SET_EXIST_STATUS,
              payload: { field: 'phoneNumberError', value: true },
            });
          } else {
            registerDispatch({
              type: REGISTER_ACTION.SET_EXIST_STATUS,
              payload: { field: 'phoneNumberExist', value: true },
            });
          }

        }

      })
    }


  }

  // CHECK PHONENUMBER PATTARN
  // const handlePhoneNumberInput = (event) => {
  //   // const newValue = event.target.value;
  //   // if (/^[0-9]*$/.test(newValue) || event.key === 'Backspace' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' ) {
  //   //   setPhoneValue(newValue);
  //   // } else {
  //   //   event.target.value = phoneValue;
  //   // }

  // };

  const onClickVerify = (verifyType) => {
    if (isProcessing.current) {
      return;
    }
    isProcessing.current = true;
    setdisabledPhoneBtn(true)

    if (!send) {
      setLoadingOTP(true)
      const { phone_number, email, currency } = getValues();
      // const checkCurrency = !currency ? appState.registration.countryDailingCode[0] : currency
      const checkCurrency = registerState.selectedCountryDailingCode || appState.registration.defaultCountryDailingCode

      const method = 'requestOTP'
      let checkFormatPhoneNumber = phone_number.replace(/^0+/, '');
      const value_destination = verifyType === 'SMS' ? `${checkCurrency}${checkFormatPhoneNumber}` : email
      // console.log('channel', verifyType)
      // console.log('destination', value_destination)
      mutation([{
        method,
        params: [
          { code: 'channel', graphqlType: 'String', required: false, value: verifyType },
          { code: 'destination', graphqlType: 'String', required: true, value: value_destination },
          { code: 'type', graphqlType: 'String', required: true, value: "VERIFIED_DESTINATION" },
          { code: 'fingerprint', graphqlType: 'String', required: false, value: visitorId },
        ],
        attributes: []

      }])
        .then(({ data }) => {
          isProcessing.current = false;
          setdisabledPhoneBtn(false)

          setLoadingOTP(false)

          registerDispatch({
            type: REGISTER_ACTION.SET_VERIFY_ID,
            payload: { field: `${verifyType === 'SMS' ? 'verifyID_SMS' : 'verifyID_EMAIL'}`, value: data[method].id },
          });

          setSend(true)
          registerDispatch({ type: REGISTER_ACTION.SET_SELECTED_VERIFY_TYPE, payload: verifyType })

          setTimeExpires(+`${appState.registration.otpPinExpiresIn}`)

          setverifyDialogShow(true)
        })
        .catch((error) => {
          isProcessing.current = false;
          setdisabledPhoneBtn(false)
          setLoadingOTP(false)
          // console.log('error: ', error)
          // console.log('error errors',error?.errors)
          // console.log('error errors2',Array.isArray(error?.errors))
          // console.log('error errors3',error?.errors[0])
          if (error && error.graphQLErrors && error.graphQLErrors.length > 0) {
            console.log('GraphQL Errors:', error.graphQLErrors);
            error.graphQLErrors.forEach((err) => {
              console.log('Error message:', err.message);
              console.log('Error extensions code:', err.extensions?.code);
              if (err.extensions?.code === 'mutationE124') {
                appDispatch({
                  type: APP_STORE_ACTION.SHOW_ALERT,
                  payload: { description: 'Your device has been blocked for phone verification', typeAlert: 'error' }
                });
                setverifyDialogShow(false);
              }
            });
          }
          // console.log('Error variable type:', typeof error);
          if (Array.isArray(error?.errors)) {
            console.log('error lala')
            error?.errors?.forEach((err) => {
              console.log('err.extensions?.code', err.extensions?.code)
              if (err.extensions?.code === 'mutationE124') {
                appDispatch({
                  type: APP_STORE_ACTION.SHOW_ALERT,
                  payload: { description: 'Your device has been blocked for phone verification', typeAlert: 'error' }
                });
                setverifyDialogShow(false)
              }
            });
          }
        })
    } else {
      isProcessing.current = false;
      setdisabledPhoneBtn(false)
      setverifyDialogShow(true)
    }






  }

  const onClickVerifyConfirm = () => {

    const { code } = getCodeValue();

    const method = 'verifyOTP'
    const valueId = registerState.selectedVerifyType === 'SMS' ? registerState.verifyID_SMS : registerState.verifyID_EMAIL
    // console.log('method', method)
    // console.log('valueId', code)

    mutation([{
      method,
      params: [
        { code: 'id', graphqlType: 'String', required: true, value: valueId },
        { code: 'pin', graphqlType: 'String', required: true, value: code },
      ],
      attributes: []

    }])
      .then(({ data }) => {
        registerDispatch({ type: REGISTER_ACTION.SET_CODE_VALIDATION_RESULT, payload: data[method] })
        // setCheckCode(data[method])

        // IF CODE VALIDATION RESULT IS TRUE
        if (data[method]) {
          setverifyDialogShow(false)
          registerDispatch({
            type: REGISTER_ACTION.SET_VERIFY_CODE,
            payload: { field: `${registerState.selectedVerifyType === 'SMS' ? 'verifyCode_SMS' : 'verifyCode_EMAIL'}`, value: code },
          });

        }

      })
      .catch((error) => {
        console.log('error: ', error)
        appDispatch({
          type: APP_STORE_ACTION.SHOW_ALERT,
          payload: { description: error.message.toString(), typeAlert: 'error' }
        });
      })

  }

  // STEP 2 SUBMIT
  const onSubmitRegister = () => {
    const values = getValues();
    const countryDailingCode = registerState.selectedCountryDailingCode || appState.registration.countryDailingCode[0]
    const method = 'registerMember'
    props.userCallback(values);
    let checkFormatPhoneNumber = values.phone_number.replace(/^0+/, '');
    let checkFullName = values.fullname.trim().replace(/\s+/g, ' ');
    console.log('fullName: ', checkFullName)
    let params = [
      { code: 'username', graphqlType: 'String', required: true, value: props.user.username },
      { code: 'password', graphqlType: 'String', required: true, value: props.user.password },
      { code: 'name', graphqlType: 'String', required: true, value: checkFullName },

    ]

    if (props.user.affiliate && props.user.affiliate !== '') {
      params.push({ code: 'affiliate', graphqlType: 'String', required: false, value: props.user.affiliate })
    }

    if (appState.registration.enabled_otp) {
      if (registerState.verifyID_SMS !== '') {
        params.push({ code: 'phone_number', graphqlType: 'String', required: false, value: `${countryDailingCode}${checkFormatPhoneNumber}` })
        params.push({ code: 'phone_validation_id', graphqlType: 'String', required: false, value: registerState.verifyID_SMS })
      }
    } else {
      if (values.phone_number !== '') {
        params.push({ code: 'phone_number', graphqlType: 'String', required: false, value: `${countryDailingCode}${checkFormatPhoneNumber}` })
      }
    }


    if (appState.registration?.emailRequired) {
      if (registerState.verifyID_EMAIL !== '') {
        params.push({ code: 'email', graphqlType: 'String', required: false, value: values.email })
        params.push({ code: 'email_validation_id', graphqlType: 'String', required: false, value: registerState.verifyID_EMAIL })
      }
    } else {
      if (values.email !== '') {
        params.push({ code: 'email', graphqlType: 'String', required: false, value: values.email })
      }

    }


    // console.log('params', params)
    // console.log('registerState.emailExist:', registerState.emailExist)
    // console.log('registerState.phoneNumberExist:', registerState.phoneNumberExist)

    if (!registerState.emailExist && !registerState.phoneNumberExist) {
      setLoading(true)
      mutation([{
        method,
        params,
        attributes: []
      }])
        .then(({ data }) => {
          setLoading(false)

          // CHECK META PIXEL
          // if (typeof fbq !== 'undefined') {

          //   if (props.user.affiliate && props.user.affiliate !== '') {

          //     query({
          //       method: 'getAffiliateMetaPixel',
          //       params: [
          //         { code: 'code', graphqlType: 'String', required: true, value: props.user.affiliate },
          //       ],
          //       attributes: []

          //     }).then(({ data }) => {

          //       const getMetaPixelByAffiliate = data['getAffiliateMetaPixel']?? ''

          //       if (getMetaPixelByAffiliate !== '') {
          //         console.log(`use affiliate pixel id:${getMetaPixelByAffiliate}`)
          //         // window.fbq('init', getMetaPixelByAffiliate);
          //         window.fbq('trackSingle', getMetaPixelByAffiliate, 'CompleteRegistration', {
          //           content_name: `Affiliate:${props.user.affiliate} Sign Up`
          //         });
          //       } else {
          //         console.log(`use default pixel id`)
          //         window.fbq('track', 'CompleteRegistration', {
          //           content_name: `Use Default Pixel ID Sign Up`
          //         });

          //       }

          //     }).catch((error) => {
          //       console.log('error: ', error)
          //       console.log(`use default pixel id`)
          //       window.fbq('track', 'CompleteRegistration', {
          //         content_name: `Use Default Pixel ID Sign Up`
          //       });
          //     })
          //   } else {
          //     console.log(`use default pixel id`)
          //     window.fbq('track', 'CompleteRegistration', {
          //       content_name: `Use Default Pixel ID Sign Up`
          //     });
          //   }

          //   console.log('Registration tracking sent.');
          // } else {
          //   console.error('fbq is not defined.');
          // }

          if (appState?.pixelID !== ''&& typeof appState?.pixelID !== 'undefined') {
            window.fbq('trackSingle', appState?.pixelID, 'CompleteRegistration');
          }

          data[method] && props.nextStep();

        })
        .catch((error) => {
          console.log('error: ', error)
          setLoading(false)
          appDispatch({
            type: APP_STORE_ACTION.SHOW_ALERT,
            payload: { description: error.message.toString(), typeAlert: 'error' }
          });
        })
    }

  };


  useEffect(() => {
    const getDefaultCountryDailingCode = appState.registration?.defaultCountryDailingCode ?? '60'
    if (appState.registration?.countryDailingCode?.includes(getDefaultCountryDailingCode)) {
      appState.registration?.countryDailingCode?.splice(appState.registration?.countryDailingCode.indexOf(getDefaultCountryDailingCode), 1);
    }

    appState.registration?.countryDailingCode?.unshift(getDefaultCountryDailingCode);

    registerDispatch({ type: REGISTER_ACTION.SET_COUNTRY_DAILING_CODE, payload: appState.registration?.countryDailingCode })


  }, [appState.registration?.countryDailingCode, appState.registration?.defaultCountryDailingCode]);

  useEffect(() => {

    const timer = setInterval(() => {
      setTimeExpires(timeExpires - 1000);
    }, 1000);

    if (timeExpires === 0) {
      clearInterval(timer);
      setSend(false)
      registerDispatch({
        type: REGISTER_ACTION.SET_VERIFY_ID,
        payload: { field: `${registerState.selectedVerifyType === 'SMS' ? 'verifyID_SMS' : 'verifyID_EMAIL'}`, value: '' },
      });
    }

    return () => {
      clearInterval(timer);
    };

  }, [timeExpires, send, registerState.selectedVerifyType]);

  useEffect(() => {

    // const otpNotSet =
    //   registerState.verifyID_SMS === '' &&
    //   registerState.verifyCode_SMS === '' &&
    //   registerState.verifyID_EMAIL === '' &&
    //   registerState.verifyCode_EMAIL === '';

    // const hasErrors =
    //   !isValid ||
    //   registerState.phoneNumberExist ||
    //   registerState.emailExist ||
    //   registerState.emailError ||
    //   registerState.phoneNumberError;

    // console.log('registerState.verifyID_SMS: ', registerState.verifyID_SMS)
    // console.log('registerState.verifyCode_SMS: ', registerState.verifyCode_SMS)

    // if (appState.registration.enabled_otp && otpNotSet) {
    //   setDisabledSubmit(true);
    // } else if (hasErrors) {
    //   setDisabledSubmit(true);
    // } else {
    //   setDisabledSubmit(false);
    // }

    if (appState.registration.enabled_otp) {
      if ((registerState.verifyID_SMS === '' || registerState.verifyCode_SMS === '') && (registerState.verifyID_EMAIL === '' || registerState.verifyCode_EMAIL === '')) {
        setDisabledSubmit(true)


      } else {
        if (!isValid || registerState.phoneNumberExist || registerState.emailExist || registerState.emailError || registerState.phoneNumberError) {
          setDisabledSubmit(true)



        } else {
          setDisabledSubmit(false)



        }
      }
    } else {
      if (!isValid || registerState.phoneNumberExist || registerState.emailExist || registerState.emailError || registerState.phoneNumberError) {
        setDisabledSubmit(true)



      } else {
        setDisabledSubmit(false)



      }
    }





  }, [registerState.verifyID_SMS, registerState.verifyID_EMAIL, isValid, registerState.phoneNumberExist, registerState.emailExist, registerState.verifyCode_SMS, registerState.verifyCode_EMAIL, appState.registration.enabled_otp, registerState.emailError, registerState.phoneNumberError]);



  useEffect(() => {
    const getVisitorId = async () => {
      const fp = await FingerprintJS.load();
      const retVal = await fp.get();
      // console.log('retVal: ', retVal)
      SetVisitorId(retVal.visitorId);
    };

    getVisitorId();
  }, [])





  return (
    <>
      <Form className="register_form_step2" onSubmit={handleSubmit(onSubmitRegister)}>

        {/* FULL NAME */}
        <Form.Group className="form_field" >
          <Form.Label>{t('full_name')}<div className="form_required">*</div></Form.Label>
          <div className="form_field_input">
            <Form.Control
              type="text"
              name="full_name"
              placeholder={t('full_name')}
              className="input_full_name"
              aria-label="Full Name"
              autoComplete="off"

              onKeyDown={(event) => {
                const isSpace = event.key === ' ';
                const isLetter = /^[a-zA-Z\s/@]*$/.test(event.key);
                const inputIsEmpty = event.target.value.length === 0;

                if ((isSpace && inputIsEmpty) || !isLetter) {
                  event.preventDefault();
                }
              }}

              onInput={(event) => {
                const isSpace = event.target.value.includes(' ');
                const inputIsEmpty = event.target.value.trim().length === 0;

                if (isSpace && inputIsEmpty) {
                  event.target.value = event.target.value.replace(/ /g, '');
                }
              }}



              {...register("fullname", getValidationRules('fullname', t))}
            />
            <p className="register_error_msg">{errors.fullname?.message}</p>
          </div>
        </Form.Group>

        {/* EMAIL */}
        {!appState.registration?.emailRegistrationDisabled && (<Form.Group className="form_field" >
          <Form.Label>{t('email')}
            {appState.registration.emailRequired && <div className="form_required">*</div>}
          </Form.Label>

          <div className="form_field_input">
            <div className="w-100 d-flex align-items-center">
              <Form.Control
                type="text"
                name="email"
                placeholder={t('email')}
                className="input_email"
                aria-label="email"
                autoComplete="off"
                onFocus={onFocusEmail}
                onKeyDown={(event) => {
                  const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', '@', '.', '-', '_', 'Shift'];
                  if (!/[a-zA-Z0-9.@_-]/.test(event.key) && !allowedKeys.includes(event.key)) {
                    event.preventDefault();
                  }
                }}
                onInput={(event) => {
                  event.target.value = event.target.value.replace(/[^a-zA-Z0-9.@_-]/g, '');
                }}
                // onBlurCapture={() => checkContact('EMAIL')
                {...register("email", getValidationRules('email', t, getValues, appState))}
              />
              {appState.registration.emailRequired && (!registerState.verifyCode_EMAIL && <button type="button" className={`color_button register_btnVerify`} disabled={!dirtyFields.email ? true : (errors.email && dirtyFields.email ? true : disabledEmailBtn)} onClick={() => { onClickVerify("EMAIL") }}>{t('verify')}</button>)}
            </div>
            <p className="register_error_msg">{errors.email?.message}</p>
            {(registerState.emailExist && !errors.email) && <p className="register_error_msg">{t('error_email_exist')}</p>}
            {(registerState.emailError && !errors.email) && <p className="register_error_msg">{t('error_email_format')}</p>}
          </div>
        </Form.Group>)}



        {/* PHONE NUMBER */}
        <Form.Group className="form_field" >
          <Form.Label >{t('phone_number')}{appState.registration.phoneRequired && <div className="form_required">*</div>} </Form.Label>
          <div className="form_field_input">
            <div className="w-100 d-flex align-items-center">
              <Form.Select aria-label="select_currency" className="select_currency w-25 me-1" disabled={registerState.verifyCode_SMS} {...register("currency")} value={registerState.selectedCountryDailingCode} onChange={(evt) => { onChangeCountryDailingCode(evt.target.value) }}>
                {(appState.registration.countryDailingCode ?? []).map(function (item, index) {
                  return (
                    <option value={item} key={index}>+{item}</option>
                  )
                })}
              </Form.Select>
              <Form.Control
                type="text"
                name="phone_number"
                placeholder={t('phone_number')}
                className="input_phone_number w-75"
                aria-label="phone_number"
                autoComplete="off"
                onFocus={onFocusPhoneNumber}
                // onBlurCapture={() => checkContact('PHONE_NUMBER')}
                disabled={registerState.verifyCode_SMS}
                onKeyDown={(event) => {
                  const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight'];
                  if (!/[0-9]/.test(event.key) && !allowedKeys.includes(event.key)) {
                    event.preventDefault();
                  }

                }}
                onInput={(event) => {
                  // event.target.value = event.target.value.replace(/[^0-9]/g, '');

                  const input = event.target.value.replace(/[^0-9]/g, '');
                  const checkCurrency = registerState.selectedCountryDailingCode || appState.registration.defaultCountryDailingCode

                  if (checkCurrency === '60') {
                    if (input.startsWith('011')) {
                      event.target.value = input.slice(0, 11);
                    } else if (input.startsWith('11')) {
                      event.target.value = input.slice(0, 10);
                    } else if (input.startsWith('0')) {
                      event.target.value = input.slice(0, 10);
                    } else {
                      event.target.value = input.slice(0, 9);
                    }
                  } else {
                    event.target.value = input;
                  }
                }}
                // onInput={handlePhoneNumberInput}
                {...register("phone_number", getValidationRules('phone_number', t, getValues, appState))}
              />
              {appState.registration.enabled_otp && (!registerState.verifyCode_SMS && <button type="button" className={`color_button register_btnVerify ${(registerState.verifyID_SMS !== '' || isLoadingOTP) && ('btnAfterVerify')}`} disabled={!dirtyFields.phone_number ? true : (errors.phone_number && dirtyFields.phone_number ? true : disabledPhoneBtn)} onClick={() => { checkContact("SMS") }}>{t('verify')}</button>)}
            </div>
            <p className="register_error_msg">{errors.phone_number?.message}</p>
            {(registerState.phoneNumberExist && !errors.phone_number) && <p className="register_error_msg">{t('error_phone_number_exist')}</p>}
            {(registerState.phoneNumberError && !errors.phone_number) && <p className="register_error_msg">{t('error_phone_number_format')}</p>}
          </div>
        </Form.Group>

        {/* POPUP VERIFICATION CODE MODAL*/}
        <Modal
          show={verifyDialogShow}
          onHide={() => setverifyDialogShow(false)}
          backdrop="static"
          keyboard={false}
          id="register_verifyDialog"
          className="verifyDialog"
          centered
        >
          <Form>
            <Modal.Header>
              <Modal.Title>{t(registerState.selectedVerifyType === 'SMS' ? "phone_number" : "email")} {t('verification_code')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <Form.Group className={`form_field`}>
                <Form.Label> {t('verification_code')}</Form.Label>
                <div className="form_field_input">
                  <div className="w-100 position-relative">
                    <Form.Control
                      type="number"
                      name="code"
                      onFocus={onFocusVerificationCode}
                      placeholder={t('verification_code')}
                      className="input_code"
                      aria-label="Code"
                      autoComplete="off"
                      {...verifyCode("code", getValidationRules('code', t))}
                    />
                    <div>
                      {minutes}:{seconds < 10 ? '0' : ''}{seconds}
                      <input type={'button'} value={t('resend')} className={`color_button btnSendCode`} onClick={() => onClickVerify(registerState.selectedVerifyType)} disabled={send} />
                    </div>
                  </div>
                  <p className="error_msg">{errorsCode.code?.message} </p>
                  {registerState.codeValidationResult === false && <p className="error_msg">{t('error_code_noMatch')} </p>}
                </div>
              </Form.Group>

            </Modal.Body>
            <Modal.Footer>

              <div>
                <button type={'button'} className={`color_button btnCancel`} onClick={() => setverifyDialogShow(false)} >{t('cancel')} </button>
                <input type={'button'} value={t('confirm')} className={`color_button`} onClick={onClickVerifyConfirm} /></div>

            </Modal.Footer>
          </Form>
        </Modal>

        {/* MOBILE REMINDER */}
        {!isDesktopOrLaptop && <div className="register_form_reminder">
          <div className="register_form_reminder_title">
            {t('reminder')}
          </div>
          <div className="register_form_reminder_content">
            <span>{t('register_reminder')}</span>
            <span>{t('register_reminder_2')}</span>
          </div>
        </div>}

        {/* BACK BUTTON / NEXT BUTTON */}
        <Form.Group className="form_field" >
          <Form.Label></Form.Label>
          <div className="form_field_input input_submit_wrap">
            <button onClick={handleBack} type='button' className="font_button color_button register_btnBack">{t('back')}</button>
            <input type='submit' className="font_button color_button register_btnNext" value={t('next')} disabled={disabledSubmit} />
          </div>
        </Form.Group>
      </Form>

      <hr className="register_form_line" />

      {/* DESKTOP REMINDER */}
      {isDesktopOrLaptop && <div className="register_form_reminder">
        <div className="register_form_reminder_title">
          {t('reminder')}
        </div>
        <div className="register_form_reminder_content">
          <span>{t('register_reminder')}</span>
          <span>{t('register_reminder_2')}</span>
        </div>
      </div>}

      {isLoading && (<Loading />)}
    </>
  );
};

// STEP 3
const Three = (props) => {
  const { t } = useTranslation();

  const handleLastStep = () => {
    props.completeCallback();
  };
  const { i18n } = useTranslation();


  return (
    <>
      <div className="register_form_step3">
        <img src={`${TENANT_BUCKET}/icon/register_successful_${i18n.resolvedLanguage}.png`} alt="register successful" />
        <h4 className="register_successful_content text-center">{t('register_successful')}</h4>
        <button type='button' className="font_button color_button register_btnDepositNow" onClick={handleLastStep}>{t('deposit_now')}</button>

        {props.birthdayReminder && (<><hr className="register_form_line" /><div><span className="register_form_reminder_title">*{t('reminder')}</span> : <span style={{ paddingLeft: '.25rem' }}> {t('register_dob_reminder')}</span></div></>)}

        {/* DESKTOP REMINDER */}

        {/* <div className="register_form_reminder">
          <div className="register_form_reminder_title">
            *{t('reminder')}
          </div>:
          <div className="register_form_reminder_content">
            {t('register_reminder')}
          </div>
        </div> */}
      </div>
    </>
  );
};

const Register = (props) => {
  // const [stepWizard, setStepWizard] = useState(null);

  const appState = useStoreState()
  const { mutation } = useMiddletier()
  const appDispatch = useStoreDispatch()
  const [toLink, setToLink] = useState('/')
  const [user, setUser] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [visitorId, SetVisitorId] = useState()


  const { desktopTitleToggle, mobileTitleToggle, birthdayReminder } = props;

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)'
  })

  const assignStepWizard = (instance) => {
    // setStepWizard(instance);
  };

  const assignUser = (val) => {
    // console.log("parent receive user callback");
    // console.log(val);
    setUser((user) => ({
      ...user,
      ...val
    }));
  };

  const handleStepChange = (e) => {
    setActiveStep(e.activeStep - 1);
  };

  const handleComplete = () => {
    // console.log('user: ', user)
    const method = 'loginByMember';
    let params = [
      { code: 'username', graphqlType: 'String', required: true, value: user.username },
      { code: 'password', graphqlType: 'String', required: true, value: user.password },
      { code: 'fingerprint', graphqlType: 'String', required: false, value: visitorId },
    ]

    mutation([{
      method,
      params,
      attributes: []
    }])
      .then(({ data }) => {
        appDispatch({ type: APP_STORE_ACTION.LOGIN, payload: { ...data[method] } })
        setToLink('/wallet/deposit')

      })
      .catch((error) => {
        console.log('error: ', error)
      })
  };

  useEffect(() => {
    const getVisitorId = async () => {
      const fp = await FingerprintJS.load();
      const retVal = await fp.get();
      // console.log('retVal: ', retVal)
      SetVisitorId(retVal.visitorId);
    };

    getVisitorId();
  }, [])


  return (
    <>
      {
        appState.loggedIn ?
          <Navigate to={toLink} /> :
          <section id="register_01">
            <div className={`${!isDesktopOrLaptop ? 'register_body_m' : ''} register_body`}>
              <PageTitle desktopToggle={false} mobileToggle={mobileTitleToggle} title={'register'} id={'register_title_m'} />
              <Container className={isDesktopOrLaptop && "register_container"}>
                <div className="register_container_wrap">
                  <div className="register_stepper">
                    <div className={`active register_stepContainer`}>
                      <button>
                        <span>1</span>
                      </button>
                    </div>
                    <div className={`${(activeStep === 1 || activeStep === 2) && 'active'} register_stepContainer`}>
                      <button>
                        <span>2</span>
                      </button>
                    </div>
                    <div className={`${activeStep === 2 && 'active'} register_stepContainer`}>
                      <button>
                        <span>3</span>
                      </button>
                    </div>
                  </div>
                  {/* NOTE: IMPORTANT !! StepWizard must contains at least 2 children components, else got error */}
                  <div className="register_form">
                    <PageTitle desktopToggle={desktopTitleToggle} mobileToggle={false} title={'register'} id={'register_title_d'} />
                    <StepWizard instance={assignStepWizard} onStepChange={handleStepChange}>
                      <One userCallback={assignUser} />
                      <Two user={user} userCallback={assignUser} />
                      <Three user={user} completeCallback={handleComplete} birthdayReminder={birthdayReminder} />
                    </StepWizard>
                  </div>
                </div>
              </Container>
            </div>
          </section>}
    </>
  );
};

export default Register;

