import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { ApolloClient, InMemoryCache, ApolloProvider, } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'

import { StoreProvider } from './common/storeContext'

import App from './App'
import MainPage from './pages/main'
import PromotionPage from './pages/promotionPage'
import HelpPage from './pages/helpPage'
import ForgotPasswordPage from './pages/forgotpwdPage'
import ForgotUserPage from './pages/forgotUserPage'
import RegisterPage from './pages/registerPage'
import SlotsPage from './pages/slotPage'
import CasinoPage from './pages/casinoPageDesktop'
import MobileSlotPage from './pages/mobileslotPageDesktop'
import SportsPage from './pages/sportPageDesktop'
import EsportsPage from './pages/esportPageDesktop'
import LotteryPage from './pages/lotteryPageDesktop'
import FishingPage from './pages/fishingPageDesktop'
import BoardGamePage from './pages/boardGamePageDesktop'
import OthersPage from './pages/othersPageDesktop'
import VIPPage from './pages/vipPage'
import ContactusPage from './pages/contactUsPage'
import WalletPage from './pages/walletPage'
import MyProfilePage from './pages/profileMobile'
import LoginPage from './pages/loginPageMobile'
import CouponPage from './pages/couponPage'
// import SponsorPage from './pages/sponsorPage'



import Deposit from './sections/wallet/banking_info/deposit/default'
import Transfer from './sections/wallet/banking_info/transfer/default'
import Withdraw from './sections/wallet/banking_info/withdraw/default'
import History from './sections/wallet/banking_info/history/default'
import Notification from './sections/wallet/my_account/notification/default'
import UserInfo from './sections/wallet/my_account/user_info/default'
import ChangePassword from './sections/wallet/my_account/change_password/default'
import ChangeGamePassword from './sections/wallet/my_account/change_game_password/default'
import BankingDetails from './sections/wallet/my_account/banking_details/default'
import MyFriend from './sections/wallet/my_account/my_friend/default'
import MyDownline from './sections/wallet/my_account/my_downline/default'
import ChangeFriendTransferPassword from './sections/wallet/my_account/change_friend_transfer_password/default'


// import i18n (needs to be bundled ;))
import './i18n';
import "bootstrap/dist/css/bootstrap.min.css";

const uploadLink = createUploadLink({
  uri: window.REACT_APP_API_URL,
  credentials: "include",
  headers: {
    'apollo-require-preflight': true,
  }
})

const client = new ApolloClient({
  cache: new InMemoryCache({ addTypename: false }),
  link: uploadLink
});

const ErrorPage = () => {
  return (<div>Error</div>)
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <MainPage />
      },
      {
        path: "/promotions",
        element: <PromotionPage />
      },
      {
        path: "/login",
        element: <LoginPage />
      },
      {
        path: "/help",
        element: <HelpPage />
      },
      {
        path: "/contact-us",
        element: <ContactusPage />
      },
      {
        path: "/forgotpassword",
        element: <ForgotPasswordPage />
      },
      {
        path: "/forgotusername",
        element: <ForgotUserPage />
      },
      {
        path: "/register",
        element: <RegisterPage />
      },
      {
        path: "/slots",
        element: <SlotsPage />
      },
      {
        path: "/mobile-slots",
        element: <MobileSlotPage />
      },
      {
        path: "/live-casino",
        element: <CasinoPage />
      },
      {
        path: "/sports",
        element: <SportsPage />
      },
      {
        path: "/esport",
        element: <EsportsPage />
      },
      {
        path: "/lottery",
        element: <LotteryPage />
      },
      {
        path: "/fishing",
        element: <FishingPage />
      },
      {
        path: "/board-game",
        element: <BoardGamePage />
      },
      {
        path: "/others",
        element: <OthersPage />
      },
      {
        path: "/vip",
        element: <VIPPage />
      }, 
      // {
      //   path: "/sponsor",
      //   element: <SponsorPage />
      // },
      {
        path: "/wallet",
        element: <WalletPage />,
        children: [
          {
            index: true,
            element: <UserInfo />,
          },
          {
            path: "deposit",
            element: <Deposit />,
          },
          {
            path: "transfer",
            element: <Transfer />,
          },
          {
            path: "withdraw",
            element: <Withdraw />,
          },
          {
            path: "history",
            element: <History />,
          },
          {
            path: "user_info",
            element: <UserInfo />,
          },
          {
            path: "notification",
            element: <Notification />,
          },
          {
            path: "change_password",
            element: <ChangePassword />,
          },
          {
            path: "change_game_password",
            element: <ChangeGamePassword />,
          },
          {
            path: "banking_details",
            element: <BankingDetails />,
          },
          {
            path: "my_friend",
            element: <MyFriend />,
          },
          {
            path: "my_downline",
            element: <MyDownline />,
          },
          {
            path: "change_friend_transfer_password",
            element: <ChangeFriendTransferPassword />,
          }
        ],
      },
      {
        path: "/my-profile",
        element: <MyProfilePage />
      },
      {
        path: "/coupon",
        element: <CouponPage />
      }
    ]
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <StoreProvider>
        <RouterProvider router={router} />
      </StoreProvider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
