// import React, { useState, useEffect } from 'react';

// const ImageWithPlaceholder = ({ src, placeholder, alt }) => {
//   const [image, setImage] = useState(placeholder);

//   useEffect(() => {
//     const img = new Image();
//     img.src = src;
//     img.onload = () => setImage(src);
//   }, [src]);

//   return <img src={image} alt={alt} loading="lazy"/>;
// };

// export default ImageWithPlaceholder;

import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const ImageWithPlaceholder = ({ src, placeholder, alt }) => (
  <LazyLoadImage
    alt={alt}
    effect="blur"
    placeholdersrc={placeholder}
    src={src} />
);

export default ImageWithPlaceholder;