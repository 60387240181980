import React, { useEffect, useState } from "react";
import { Container, Nav, FormCheck, OverlayTrigger, Popover } from "react-bootstrap";
import {
  Outlet,
  NavLink, useLocation, useNavigate
} from "react-router-dom";
import { FaMobileAlt, FaAddressCard, FaEnvelope } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { WALLET_MENU_DATA, WALLET_MENU_DATA_OLD, TENANT_BUCKET, USER_VERIFICATION_DATA, TENANT_NAME, CHECK_NEW_TENANT } from '../../common/constants';
import { useStoreDispatch, useStoreState, APP_STORE_ACTION } from '../../common/storeContext';
import { useMiddletier } from "../../common/middletier";
import PromoClaimDialog from '../../components/PromoClaimDialog';
import ShareQRDialog from '../../components/ShareQRDialog';
import { BsFillQuestionCircleFill } from "react-icons/bs";

import Loading from '../../components/Loading';
import QRCode from 'qrcode.react';
import "./template_01.css";

// const WALLET_ACTION = {
//   SET_RESTORE_WALLET_AMOUNT: 'SET_RESTORE_WALLET_AMOUNT',
// }


// const walletReducer = (state, action) => {
//   switch (action.type) {

//     case WALLET_ACTION.SET_RESTORE_WALLET_AMOUNT: {
//       return { ...state, wallet_amount: action.payload };
//     }


//     default: {
//       throw new Error(`Unhandled action type: ${action.type}`)
//     }
//   }
// }



const WalletBar = (props) => {
  const appDispatch = useStoreDispatch()
  const appState = useStoreState()
  const navigate = useNavigate();
  const { queries, mutation } = useMiddletier();
  const [promoClaimShow, setPromoClaimShow] = useState(false);
  const [memberGroupImage, setMemberGroupImage] = useState(``);
  const [showShareQRDialog, setShowShareQRDialog] = useState(false);
  const [showSecurityPassword, setShowSecurityPassword] = useState(false);

  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);

  const onClickPointAlert = () => {
    appDispatch({
      type: APP_STORE_ACTION.SHOW_ALERT,
      payload: { info: 'point' }
    });
  }

  const onSubmitLogout = () => {
    mutation([
      {
        method: 'logout',
        params: [],
        attributes: []
      }
    ])
      .then(({ data }) => {
        appDispatch({ type: APP_STORE_ACTION.LOGOUT })
        navigate('/')
      })
      .catch((error) => {
        console.error('error: ', error)
        appDispatch({ type: APP_STORE_ACTION.LOGOUT })
        navigate('/')
      })
  }

  // const onClickRestore = () => {
  //   setLoading(true)
  //   const method = 'restoreGameWalletByMember'

  //   mutation([{
  //     method,
  //     params: [],
  //     attributes: []

  //   }]).then(({ data }) => {

  //     query({
  //       method: 'getAllGamePrivoderBalanceByMember',
  //       params: [],
  //       attributes: []

  //     }).then(({ data }) => {
  //       onClickRefreshBalance()
  //       appDispatch({ type: APP_STORE_ACTION.REFRESH_GET_GAME_WALLET, payload: data['getAllGamePrivoderBalanceByMember'] })
  //       setLoading(false)

  //     }).catch((error) => {
  //       setLoading(false)
  //       console.log('error: ', error)

  //       if (error?.networkError?.statusCode === 401) {
  //         appDispatch({ type: APP_STORE_ACTION.LOGOUT })
  //         navigate('/')
  //       }
  //     })

  //   }).catch((error) => {
  //     setLoading(false)
  //     console.log('error: ', error)
  //   })
  // }

  // const onClickRefreshBalance = () => {
  //   const method = 'member'

  //   query({
  //     method,
  //     params: [
  //       { code: 'username', graphqlType: 'String', required: true, value: appState.user?.username },
  //     ],
  //     attributes: ['wallet_amount']

  //   }).then(({ data }) => {

  //     appDispatch({ type: APP_STORE_ACTION.REFRESH_WALLET_AMOUNT, payload: data[method].wallet_amount })

  //   }).catch((error) => {
  //     console.log('error: ', error)
  //     if (error?.networkError?.statusCode === 401) {
  //       appDispatch({ type: APP_STORE_ACTION.LOGOUT })
  //       setAlertShow({ show: true, message: error.toString(), type: 'error' });
  //       navigate('/')
  //     }
  //   })
  // }

  // const formatAmount = (amount, decimalPoint) => {
  //   const factor = Math.pow(10, decimalPoint);
  //   const truncatedAmount = Math.floor(amount * factor) / factor;
  //   const formattedAmount = truncatedAmount.toLocaleString(appState.currencyFormat?.locale, {
  //     minimumFractionDigits: decimalPoint,
  //     maximumFractionDigits: decimalPoint,
  //   });
  //   return formattedAmount;
  // }
  const formatAmount = (amount, decimalPoint) => {
    let strAmount = Number(amount).toString();
    let dotIndex = strAmount.indexOf('.');
  
    if (dotIndex === -1) {
      strAmount += '.' + '0'.repeat(decimalPoint);
    } else {
      const actualDecimals = strAmount.length - dotIndex - 1;
      if (actualDecimals > decimalPoint) {
        strAmount = strAmount.substring(0, dotIndex + decimalPoint + 1);
      } else if (actualDecimals < decimalPoint) {
        strAmount += '0'.repeat(decimalPoint - actualDecimals);
      }
    }
  
    const formattedAmount = parseFloat(strAmount).toLocaleString(appState.currencyFormat?.locale, {
      minimumFractionDigits: decimalPoint,
      maximumFractionDigits: decimalPoint,
    });
  
    return formattedAmount;
  };

  // AUTO TRANSFER

  const onChangeAutoTransfer = () => {
    setLoading(true)
    const currentAutoTransfer = !appState.user?.auto_restore
    // console.log('currentAutoTransfer: ', currentAutoTransfer)
    const method = 'changeAutoRestoreByMember'

    mutation([{
      method,
      params: [
        { code: 'autoRestore', graphqlType: 'Boolean', required: true, value: currentAutoTransfer },
      ],
      attributes: []

    }]).then(({ data }) => {
      appDispatch({ type: APP_STORE_ACTION.AUTO_TRANSFER, payload: currentAutoTransfer })

      if (currentAutoTransfer) {
        appDispatch({
          type: APP_STORE_ACTION.SHOW_ALERT,
          payload: { description: t('auto_transfer_on'), typeAlert: 'info', title: t('main_wallet_auto_transfer') }
        });
      } else {
        appDispatch({
          type: APP_STORE_ACTION.SHOW_ALERT,
          payload: { description: t('auto_transfer_off'), typeAlert: 'info', title: t('main_wallet_auto_transfer') }
        });
      }
      setLoading(false)


    }).catch((error) => {
      setLoading(false)

      console.log('error: ', error)
      if (error?.networkError?.statusCode === 401) {
        appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
      } else {
        appDispatch({
          type: APP_STORE_ACTION.SHOW_ALERT,
          payload: { description: error.message.toString(), typeAlert: 'error' }
        });
      }
    })
  }

  useEffect(() => {
    // const method = 'member_bank_accounts'

    queries([{
      method: 'member_group',
      params: [
        { code: 'id', graphqlType: 'String', required: true, value: appState?.user?.member_group ?? '' },
      ],
      attributes: [['_logo', ['url']]]

    }]).then(({ data }) => {

      setMemberGroupImage(data['member_group']?._logo ? data['member_group']?._logo.url : `${TENANT_BUCKET}/icon/wallet_user.png`)

    }).catch((error) => {
      console.log('error: ', error)
      if (error?.networkError?.statusCode === 401) {
        appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
      } else {
        appDispatch({
          type: APP_STORE_ACTION.SHOW_ALERT,
          payload: { description: error.message.toString(), typeAlert: 'error' }
        });
      }
    })

  }, [appState.user, queries, navigate, appDispatch]);


  useEffect(() => {
    // const method = 'member_bank_accounts'
    // setLoading(true)
    if (typeof appState.user?.username !== 'undefined') {
    queries([{
      method: 'member',
      params: [
        { code: 'username', graphqlType: 'String', required: true, value: appState?.user?.username },
      ],
      attributes: ['friend_transfer_password']

    }]).then(({ data }) => {

      // setLoading(false)
      if (data['member']?.friend_transfer_password !== null) {
        setShowSecurityPassword(true)

      } else {
        setShowSecurityPassword(false)
      }

    }).catch((error) => {
      console.log('error: ', error)
      if (error?.networkError?.statusCode === 401) {
        appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
      } else {
        appDispatch({
          type: APP_STORE_ACTION.SHOW_ALERT,
          payload: { description: error.message.toString(), typeAlert: 'error' }
        });
      }
    })
  }
  }, [appState.user?.username, queries, navigate, appDispatch]);



  return (
    <>
      <Nav variant="pills" className="walletBar">
        <div className="walletBar_Info">
          <div className="walletBar_userInfo">
            {memberGroupImage && (<img src={`${memberGroupImage}`} alt={'wallet user'} />)}
            <div>
              {/* <span className="d-block">{(t("welcome"))}</span> */}
              <span className="walletBar_userName d-block">{appState.user?.username}</span>
              {appState.enableLoyalty && (<div className="wallet_point"><span className="me-1">{formatAmount(appState.user?.reward_points ?? 0, appState.currencyFormat.decimal_point)}</span>
                {t('point')}  <BsFillQuestionCircleFill onClick={onClickPointAlert} /></div>)}
            </div>
          </div>
          {CHECK_NEW_TENANT && (<div className="walletBar_walletUserQrInfo" onClick={() => setShowShareQRDialog(true)}>
            <div className="walletBar_walletUserQr">
              <QRCode value={`https://${appState?.domain}/`} renderAs="svg" height="40" width="40" />
            </div>
            <div className="walletBar_walletUserQrUrl">
              <button className="font_button color_button shareLink_button" onClick={() => setShowShareQRDialog(true)} >{t('share_link')}</button>
            </div>
          </div>)}
          {/* <div className="walletBar_walletInfo">
            <div className="walletBar_walletTitle">
              {(t("main_wallet"))}
            </div>
            <div className="walletBar_walletBalance">
              <div className="walletBar_balanceCurrency">{appState.user?.currency}</div>
              <div className="walletBar_balanceAmount">{formatAmount(appState.user?.wallet_amount ?? 0, appState.currencyFormat.decimal_point)}</div>
            </div>
            <div className="walletBar_walletActionButton">
              <button className="font_button color_button walletBar_btnRestore" type="button" onClick={() => onClickRestore()}>
                <img src={`${TENANT_BUCKET}/icon/restore_icon.png`} alt="" onClick={() => onClickRestore()} />
                {(t("restore"))}
              </button>
              <button className="font_button color_button walletBar_btnPromoClaim" onClick={() => setPromoClaimShow(true)}>
                <img src={`${TENANT_BUCKET}/icon/promo_claim_icon.png`} alt="" onClick={() => onClickRestore()} />
                {(t("promo_claim"))}
              </button>
            </div>
          </div> */}
          <div className="walletBar_userVerification">
            <div className="walletBar_userVerification_title">{(t("user_verification"))}</div>
            <div className="d-flex align-items-center">
              {USER_VERIFICATION_DATA.map(function (item, index) {
                const _item = appState?.user?.[item?.id] ?? ''
                return (
                  <div key={item.id}>
                    <OverlayTrigger

                      key={'right'}
                      placement={'right'}
                      overlay={
                        <Popover>
                          <Popover.Body>
                            {_item ? (t(`${item?.name}_verifed_content`)) : (t(`${item?.name}_unverifed_content`))}
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <div className={`verify_icon ${_item ? 'validated' : ''}`}>
                        {item?.name === 'phone_number' ? <FaMobileAlt /> : (item?.name === 'email' ? <FaEnvelope /> : (item?.name === 'dob' ? <FaAddressCard /> : <></>))}
                      </div>
                    </OverlayTrigger>

                  </div>
                )
              })}
            </div>
          </div>
          {/* {appState.enableLoyalty && (<div className="walletBar_point">
            <div className="walletBar_point_title">{TENANT_NAME.toUpperCase()} {(t("point"))}</div>
            <div className="d-flex align-items-center justify-content-end">
              <span>{formatAmount(appState.user?.reward_points ?? 0, appState.currencyFormat.decimal_point)}</span>
              {t('points')}
            </div>
          </div>)} */}
          <div className="walletBar_autoTransfer">
            <FormCheck custom type="switch">
              <FormCheck.Label >
                {(t("auto_transfer"))}
              </FormCheck.Label>
              <FormCheck.Input checked={appState.user?.auto_restore ?? false} onChange={() => onChangeAutoTransfer()} />
            </FormCheck>
          </div>

        </div>
        <Nav.Item>
          {Object.keys((appState?.enableFriend ? WALLET_MENU_DATA_OLD : WALLET_MENU_DATA_OLD)).map((key, index) => {
            return (
              <div key={index}>
                <div className="font_h3 walletBar_title">{(t(key))}</div>
                {(appState?.enableFriend ? WALLET_MENU_DATA_OLD : WALLET_MENU_DATA_OLD)[key].map(function (item, index) {
                  return (
                    <NavLink to={`${item.url}`} key={item.id}  onClick={(event) => {if (item?.name === "change_friend_transfer_password" && !showSecurityPassword) {event.preventDefault();} }}>
                      {({ isActive }) => (
                        <span className={`walletBar_selection ${isActive ? 'color_active active':''} ${item?.name === "change_friend_transfer_password" && !showSecurityPassword ? 'disabled' : ''}`}>
                          <img src={isActive ? item.selectedIcon : item.icon} alt={'wallet bar icon'} />
                          {(t(item.name))}
                        </span>
                      )}
                    </NavLink>
                  )
                })}
              </div>
            )
          })
          }
        </Nav.Item>
        <button className="font_button color_button walletBar_btnLogout" onClick={onSubmitLogout}>
          {t('logout')}
        </button>
      </Nav>
      {/* <Alert show={alertShow.show} onHide={onHideAlert} message={alertShow.message} type={alertShow.type} title={alertShow.title} /> */}
      <PromoClaimDialog
        show={promoClaimShow}
        onHide={() => setPromoClaimShow(false)}
      />
      {isLoading && (<Loading />)}
      {showShareQRDialog && (<ShareQRDialog show={showShareQRDialog} onHide={() => setShowShareQRDialog(false)} shareURL={`https://${appState?.domain}/`} avatarLogo={memberGroupImage} />)}

    </>
  )
}



const Wallet = () => {
  const appDispatch = useStoreDispatch()
  const appState = useStoreState()
  const location = useLocation()
  const [isLoading, setLoading] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)'
  })
  const { mutation } = useMiddletier();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])


  useEffect(() => {

    if (appState.loggedIn === false) {
      // setLoading(true)
      if (appState.loginWithToken === true) {
        setLoading(false)
        mutation([
          {
            index: 'loginWithTokenByMember',
            method: 'loginWithTokenByMember',
            params: [],
            attributes: []
          }
        ])
          .then(({ data }) => {
            // console.log('login token')
            setLoading(false)
            appDispatch({ type: APP_STORE_ACTION.LOGIN, payload: { ...data['loginWithTokenByMember'] } })

          })
          .catch((error) => {
            // console.log('login error')
            setLoading(false)
            console.error(error)
            appDispatch({ type: APP_STORE_ACTION.SET_LOGIN_WITH_TOKEN, payload: false })
            if (error?.networkError?.statusCode === 401) {
              appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
            } else {
              appDispatch({
                type: APP_STORE_ACTION.SHOW_ALERT,
                payload: { description: error.message.toString(), typeAlert: 'error' }
              });
            }
          })
      } else {
        // console.log('login false')
        setLoading(false)
        appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
      }
    } else {
      // console.log('login true')
      setLoading(false)
    }
  }, [mutation, appDispatch, appState.loggedIn, appState.loginWithToken, navigate])


  return (

    <>
      <section id='wallet_01'>
        <div className="wallet_body">
          <Container className={`wallet_body_container ${!isDesktopOrLaptop ? 'wallet_body_container_m' : ''}`}>
            {isDesktopOrLaptop && <WalletBar />}
            <div className={`wallet_container ${!isDesktopOrLaptop ? 'wallet_container_m' : ''}`}>
              <Outlet />
            </div>
          </Container>
        </div>
      </section>
      {isLoading && (<Loading />)}
      {/* <Alert show={alertShow.show} onHide={onHideAlert} message={alertShow.message} type={alertShow.type} title={alertShow.title} /> */}
    </>
  );
}

export default Wallet;
