import React, { useState } from "react";
import { useStoreDispatch, APP_STORE_ACTION } from '../../../../common/storeContext';
import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import Title from '../../../pageTitle/default.js';
import { useForm } from "react-hook-form";

import { useMiddletier } from "../../../../common/middletier";
import Loading from '../../../../components/Loading';
import { useNavigate } from "react-router-dom";


const MyDownline = () => {

    const { t } = useTranslation();

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 992px)'
    })

    return (
        <>
            <div className="myDownline_body">
                {isDesktopOrLaptop ? <>
                    <div className="font_h2 color_primary wallet_container_title">{t('my_downline')}</div>
                    <div className="wallet_container_wrap">

                  
                    </div>
                </> : <>
                    <Title title={"my_downline"} desktopToggle={false} mobileToggle={true} id={'myDownline_title'} />
                    <div className="myAccount_wrap">
                        
                    </div>
                </>}
            </div>
        </>

    );




};






export default MyDownline;
