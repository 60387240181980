import React, { useEffect, useState } from "react";
import { Link, useLocation, NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { useStoreState,APP_STORE_ACTION ,useStoreDispatch} from '../../common/storeContext'
import { GLOBAL_BUCKET, TENANT_NAME, BOTTOM_MENU_DATA, TENANT_BUCKET } from '../../common/constants'
import { useMiddletier } from "../../common/middletier";
import { SOCIAL_MEDIA_DATA } from '../../common/constants';
import "./template_01.css";

const Footer_01 = (props) => {
  const appState = useStoreState()
  const [show, toggleShow] = React.useState(false);
  const { t, i18n } = useTranslation();
  const { queries } = useMiddletier();
  const [socialMedia, setSocialMedia] = useState([])
  const [paymentMethod, setPaymentMethod] = useState([])
  const [license, setLicense] = useState([])
  const [help, setHelp] = useState([])
  const [gameProviders, setGameProviders] = useState([])
  const appDispatch = useStoreDispatch()



  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)'
  })
  const location = useLocation();

  const isProfilePage = location.pathname === '/my-profile';

  const openChat = () => {
    if (window.LC_API && typeof window.LC_API.open_chat_window === 'function') {
      const event = new Event('openChat');
      window.dispatchEvent(event);
    }
  };

  useEffect(() => {

    queries([{
      index: 'getSocialMedia',
      method: 'getSocialMedia',
      params: [],
      attributes: []
    },
    {
      index: 'getPaymentMethods',
      method: 'getPaymentMethods',
      params: [],
      attributes: []
    }, {
      index: 'getLicense',
      method: 'getLicense',
      params: [{ code: 'filter', graphqlType: 'JSON', required: true, value: { where: { status: 'ACTIVE' } } },],
      attributes: ['code', 'url']
    },
    {
      index: 'getFaq',
      method: 'getFaq',
      params: [
        { code: 'language', graphqlType: 'String', required: true, value: i18n.resolvedLanguage },
        { code: 'category', graphqlType: 'String', required: false, value: 'HELP' }
      ],
      attributes: []
    },
    {
      index: 'getGameProviders',
      method: 'getGameProviders',
      params: [
        { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { '$_game_provider_game_types.status$': "ACTIVE", status: 'ACTIVE' }, order: [['index', 'ASC'], ['name', 'ASC']] } },
      ],
      attributes: ['code', 'name', ['_game_provider_game_types', ['type']]]
    },
    ])
      .then(({ data }) => {

        const getSocialMediaData = data['getSocialMedia'].map((item) => {
          const _item = SOCIAL_MEDIA_DATA[item.code];
          return { ...item, value: JSON.parse(item.value), category: _item ? _item.category : null, icon: _item ? _item.icon : null };
        }).filter((item) => item.category === 'socialMedia' && item.value['url'] !== "");

        setSocialMedia(getSocialMediaData)
        setPaymentMethod(data['getPaymentMethods'])
        setLicense(data['getLicense'])
        setHelp(data['getFaq'])
        setGameProviders(data['getGameProviders'])
        // console.log('hihi: ', getSocialMediaData)

      }).catch((error) => {
        console.log('error: ', error)
      })

  }, [queries, i18n.resolvedLanguage]);

  const { desktopToggle, mobileToggle, game_licenseToggle, certificationToggle, payment_methodToggle, suggested_browserToggle, follow_usToggle, responsible_gamingToggle, footer_titleToggle_desktop, footer_titleToggle_mobile, footer_infoToggle, position, bottomMenuToggle, gameProvidersToggle, providerRowDesktop, providerRowMobile, shareHolderToggle, infoPosition, folderImage } = props;
  const widthColumn = isDesktopOrLaptop ? (providerRowDesktop ? 100 / providerRowDesktop : 0) : (providerRowMobile ? 100 / providerRowMobile : 0)


  if (((desktopToggle || mobileToggle) === true) && !isProfilePage) {
    return (
      <>
      
        <footer className={`section ${desktopToggle ? "" : "d-lg-none"} ${mobileToggle ? "" : "d-none d-lg-block"} ${(!isDesktopOrLaptop && appState.loggedIn && bottomMenuToggle) && 'bottomMenuFooter'}`} id="footer_01">
          <div className="footer font_h4 color_footer">
            <div className={` footer_container ${position === 'horizontal' ? 'footer_container_horizontal container-lg' : ''}`}>
              <div className={`footer_row 2 footer_menu_container ${footer_titleToggle_desktop ? "" : "d-lg-none"} ${footer_titleToggle_mobile ? (isDesktopOrLaptop ? "" : "footer_title_m") : "d-none d-lg-block"}`}>
                <div className="container-lg footer_menu_wrap">
                  {isDesktopOrLaptop ? <div className="font_h5 footer_help">
                    {help?.map((item, index) => {
                      return (
                        <div key={index}>
                          <Link to={`/help#${item?.faq}`} >{item.title}</Link>{position !== 'horizontal' && (<>︙</>)}
                        </div>
                      );
                    })}
                    <div><Link to="/contact-us" >{t('contact_us')}</Link>{position !== 'horizontal' && (<>︙</>)}</div>
                  </div> : <>
                    {help.length > 0 && (<details>
                      <summary className="footer_info_summary">
                        <div className="footer_info_text">{t('help')}</div>
                      </summary>
                      <div className="footer_info_selection">
                        {help?.map((item, index) => {
                          return (
                            <div key={index}>
                              <Link to={`/help#${item?.faq}`} >{item.title}</Link>
                            </div>
                          );
                        })}
                      </div>
                    </details>)}
                    <div className="footer_info_text">
                      <Link to="/contact-us" >{t('contact_us')}</Link>
                    </div>
                  </>}

                </div>
              </div>
              <div className={`${position === 'horizontal' ? 'footer_info_container_wrap' : 'container-lg'}`}>
                <div className={`footer_info_container footer_info_${infoPosition ?? 'center'}`}>
                  {footer_infoToggle && <div className="row footer_row 3 footer_seo_wrap">
                    <div className="seo_footer_container">
                      {!show && <div className="text-uppercase m-0 seo_footer_title">{t('footer_title')}</div>}
                      <div className="font_h5 seo_footer_content">
                        {!show && t('footer_details')}
                        <span onClick={() => toggleShow(!show)}> {show ? t('hide_info') : t('read_more')}</span>
                        {show && (<div className="footer_seo_all">
                          <div className="footer_seo_all_col">
                            <div className="footer_seo__title">
                              {t('footer_details_title_01')}
                            </div>
                            <div className="footer_seo_details">
                              {t('footer_details_content_01')}
                            </div>
                            <div className="footer_seo__title">
                              {t('footer_details_title_02')}
                            </div>
                            <div className="footer_seo_details">
                              {t('footer_details_content_02')}
                            </div>
                          </div>
                          <div className="footer_seo_all_col">
                            <div className="footer_seo__title">
                              {t('footer_details_title_03')}
                            </div>
                            <div className="footer_seo_details">
                              {t('footer_details_content_03')}
                            </div>
                            <div className="footer_seo__title">
                              {t('footer_details_title_04')}
                            </div>
                            <div className="footer_seo_details">
                              {t('footer_details_content_04')}
                            </div>
                          </div>
                        </div>)
                        }
                      </div>
                    </div>
                    <div>


                    </div>


                  </div>}
                  {gameProvidersToggle && <div className="row footer_row footer_gameProviders_wrap">
                    {gameProviders?.map((item, index) => {
                      return (
                        <div key={`${item.code}_${index}`} style={{ width: widthColumn + '%' }}>
                          <img src={`${folderImage === 'footer' ? `${TENANT_BUCKET}/footer/game_provider` : `${GLOBAL_BUCKET}/game_provider_logo`}/1x1/${item.code}.png`} alt={`${item.code}`} />
                        </div>
                      );
                    })}
                  </div>}
                  <div className="row footer_row footer_licenses_wrap">
                    {game_licenseToggle &&
                      <div className="footer_info footer_gaming_license">
                        <div className="footer_title">{t('gaming_license')}</div>
                        <ul className="footer_info_img">
                          {license?.map(function (item, index) {
                            if (item?.url) {
                              return (

                                <Link to={`${item?.url}`} target="_blank">
                                  <li key={item.code}>
                                    <img src={`${folderImage === 'footer' ? `${TENANT_BUCKET}/footer` : `${GLOBAL_BUCKET}`}/gaming_license/${item.code}.png`} alt="footer license" />
                                  </li>
                                </Link>
                              )
                            } else {
                              return (
                                <li key={item.code}>
                                  <img src={`${folderImage === 'footer' ? `${TENANT_BUCKET}/footer` : `${GLOBAL_BUCKET}`}/gaming_license/${item.code}.png`} alt="footer license" />
                                </li>
                              )
                            }

                          })}
                          {/* <li>
                        <img src={`${GLOBAL_BUCKET}/gaming_license/PAGCOR.png`} alt="footer license" />
                      </li> */}
                        </ul>
                      </div>}

                    {certificationToggle && <div className="footer_info">
                      <div className="footer_title">{t('certification')}</div>
                      <ul className="footer_info_img">
                        <li>
                          <img src={`${folderImage === 'footer' ? `${TENANT_BUCKET}/footer` : `${GLOBAL_BUCKET}`}/certifications/bmm.png`} alt="footer certification" />
                        </li>
                        <li>
                          <img src={`${folderImage === 'footer' ? `${TENANT_BUCKET}/footer` : `${GLOBAL_BUCKET}`}/certifications/itechlab.png`} alt="footer certification" />
                        </li>
                        <li>
                          <img src={`${folderImage === 'footer' ? `${TENANT_BUCKET}/footer` : `${GLOBAL_BUCKET}`}/certifications/secured.png`} alt="footer certification" />
                        </li>
                      </ul>
                    </div>}



                    {payment_methodToggle && <div className="footer_info footer_payment_method">
                      <div className="footer_title">{t('payment_methods')}</div>
                      <ul className="footer_info_img">
                        <li>
                          <img src={`${folderImage === 'footer' ? `${TENANT_BUCKET}/footer` : `${GLOBAL_BUCKET}`}/payment/BANKTRANSFER.png`} alt="footer payment method" />
                        </li>
                        {paymentMethod.map(function (item, index) {
                          return (
                            <li key={item.code}>
                              <img src={`${folderImage === 'footer' ? `${TENANT_BUCKET}/footer` : `${GLOBAL_BUCKET}`}/payment/${item.code}.png`} alt="" />
                            </li>
                          )
                        })}
                        {/* <li>
                    <img src={`${GLOBAL_BUCKET}/payment/ipay4u.png`} alt="footer payment method" />
                  </li>
                  <li>
                    <img src={`${GLOBAL_BUCKET}/payment/duitnow.png`} alt="footer payment method" />
                  </li>
                  <li>
                    <img src={`${GLOBAL_BUCKET}/payment/tng.png`} alt="footer payment method" />
                  </li>
                  <li>
                    <img src={`${GLOBAL_BUCKET}/payment/grabpay.png`} alt="footer payment method" />
                  </li>
                  <li>
                    <img src={`${GLOBAL_BUCKET}/payment/boost.png`} alt="footer payment method" />
                  </li>
                  <li>
                    <img src={`${GLOBAL_BUCKET}/payment/shopeepay.png`} alt="footer payment method" />
                  </li> */}
                      </ul>
                    </div>}

                    {suggested_browserToggle && <div className="footer_info">
                      <div className="footer_title">{t('suggested_browser')}</div>
                      <ul className="footer_info_img">
                        <li>
                          <img src={`${folderImage === 'footer' ? `${TENANT_BUCKET}/footer` : `${GLOBAL_BUCKET}`}/browser/chrome.png`} alt="footer suggested browser" />
                        </li>
                        <li>
                          <img src={`${folderImage === 'footer' ? `${TENANT_BUCKET}/footer` : `${GLOBAL_BUCKET}`}/browser/firefox.png`} alt="footer suggested browser" />
                        </li>
                      </ul>
                    </div>}



                    {(follow_usToggle && socialMedia.length > 0) && <div className="footer_info footer_follow_us">
                      <div className="footer_title">{t('follow_us')}</div>
                      <ul className="footer_info_img">
                        {socialMedia.map(function (item, index) {
                          return (
                            <li key={item.code}>
                              <a href={item.value['url'] === "" ? "#" : item.value['url']} onClick={(e) => {
                                if (item.value['url'] === "") {
                                  e.preventDefault();
                                }
                              }} target="_blank" rel="noopener noreferrer"> <img src={`${folderImage === 'footer' ? `${TENANT_BUCKET}/footer` : `${GLOBAL_BUCKET}`}/social/${item.icon}.png`} alt={item.name} /></a>
                            </li>
                          )
                        })}
                        {/* <li>
                    <img src={`${GLOBAL_BUCKET}/social/facebook.png`} alt="footer follow us" />
                  </li>
                  <li>
                    <img src={`${GLOBAL_BUCKET}/social/instagram.png`} alt="footer follow us" />
                  </li>
                  <li>
                    <img src={`${GLOBAL_BUCKET}/social/twitter.png`} alt="footer follow us" />
                  </li>
                  <li>
                    <img src={`${GLOBAL_BUCKET}/social/youtube.png`} alt="footer follow us" />
                  </li>
                  <li>
                    <img src={`${GLOBAL_BUCKET}/social/tiktok.png`} alt="footer follow us" />
                  </li> */}
                      </ul>
                    </div>}

                    {responsible_gamingToggle && <div className="footer_info">
                      <div className="footer_title">{t('responsible_gaming')}</div>
                      <ul className="footer_info_img">
                        <li>
                          <img src={`${folderImage === 'footer' ? `${TENANT_BUCKET}/footer` : `${GLOBAL_BUCKET}`}/responsible_gaming/18+.png`} alt="footer responsible gaming" />
                        </li>
                        <li>
                          <img src={`${folderImage === 'footer' ? `${TENANT_BUCKET}/footer` : `${GLOBAL_BUCKET}`}/responsible_gaming/begambleaware.png`} alt="footer responsible gaming" />
                        </li>
                        <li>
                          <img src={`${folderImage === 'footer' ? `${TENANT_BUCKET}/footer` : `${GLOBAL_BUCKET}`}/responsible_gaming/gamcare.png`} alt="footer responsible gaming" />
                        </li>
                      </ul>
                    </div>
                    }

                    {shareHolderToggle && <div className="footer_info">
                      <Link to={`/`}><div className="footer_title">{t('shareholder')}</div></Link>
                      {/* <ul className="footer_info_img">
                        <li>
                          <img src={`${GLOBAL_BUCKET}/responsible_gaming/18+.png`} alt="footer responsible gaming" />
                        </li>
                        <li>
                          <img src={`${GLOBAL_BUCKET}/responsible_gaming/begambleaware.png`} alt="footer responsible gaming" />
                        </li>
                        <li>
                          <img src={`${GLOBAL_BUCKET}/responsible_gaming/gamcare.png`} alt="footer responsible gaming" />
                        </li>
                      </ul> */}
                    </div>
                    }
                  </div>

                </div>
              </div>
              <div className="row footer_row footer_copyright_container">
                  <div className="font_h5 footer_copyright_box">
                    <div className="text-center footer_copyright">© 2024 Copyright: {TENANT_NAME.toUpperCase()}</div>
                  </div>
                  <div className="font_h5 footer_copyright_box footer_version_box">
                    <div className="text-center footer_copyright">Version: {window.BUILD_VERSION}</div>
                  </div>
                </div>
            </div>
          </div>

        </footer>
        {(!isDesktopOrLaptop && appState.loggedIn && bottomMenuToggle) && (
          <div className="bottomNav">
            {BOTTOM_MENU_DATA?.map((item, index) => {
              if (item.name === 'live_chat') {
                return (
                  <div key={`${item.name}_${index}`} className="bottomNav_selection" onClick={() => openChat()}>
                    <img src={item.icon} alt="bottom nav icon" />
                    <span>{t(item.name)}</span>
                  </div>
                );
              } else {
                return (
                  <NavLink to={`${item.url}`} key={`${item.name}_${index}`} className="bottomNav_selection">
                    {({ isActive }) => (
                      <>
                        <img src={isActive ? item.selectedIcon : item.icon} alt="bottom nav icon" />
                        <span className={`${isActive ? 'active' : ''}`}>{t(item.name)}</span>
                      </>
                    )}
                  </NavLink>
                );
              }

            })}
          </div>
        )}
      </>
    );
  } else { }
};

export default Footer_01;
