import React, { useEffect, useState } from "react";
import { Container, Card, Modal } from "react-bootstrap";
// import { TENANT_BUCKET } from '../../common/constants'
import PageTitle from '../pageTitle/template_01';
import { useMiddletier } from "../../common/middletier";
import { SOCIAL_MEDIA_DATA } from '../../common/constants';
import { useMediaQuery } from 'react-responsive';
import "./template_01.css";


const ContactUs = (props) => {
  const { desktopTitleToggle, mobileTitleToggle, dropdownTitle, backgroundImgTitle } = props;
  const [contactUs, setContactUs] = useState([])
  const [showContactImgDialog, setShowContactImgDialog] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)'
  })
  const { query } = useMiddletier();
  // (rowDesktop ? 100 / rowDesktop : 0) 
  // const title_desc = "Thank you for reaching out FlexGaming. Have you tried our FAQ to see if there's an answer you might be looking for? We've provided some of our top areas of inquiries. If you haven't found what you're looking for,listed below is a variety of convenient ways for you to contact us."

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  useEffect(() => {
    const method = 'getSocialMedia'

    query({
      method,
      params: [],
      attributes: []

    }).then(({ data }) => {

      const getSocialMediaData = data[method].map((item) => {
        const _item = SOCIAL_MEDIA_DATA[item.code];
        return { ...item, value: JSON.parse(item.value), category: _item ? _item.category : null, icon: _item ? _item.icon : null };
      }).filter((item) => item.category === 'contactUs' && (item.name === "Livechat" ? item.value?.license_id !== "" : item.value?.url !== "" || item.value?.account !== ""));
      setContactUs(getSocialMediaData)
      // console.log('hihi', getSocialMediaData)

    }).catch((error) => {
      console.log('error: ', error)
    })

  }, [query]);

  const openChat = () => {
    const event = new Event('openChat');
    window.dispatchEvent(event);
  };

  const onClickImage = (item) => {
    setCurrentImage(item)
    setShowContactImgDialog(true)
  };

  return (
    <section id="contactUs_01">
      <div className="contactUs_body">

        <PageTitle desktopToggle={false} desktopHeadingHide={true} mobileHeadingHide={false} mobileToggle={mobileTitleToggle} title={'contact_us'} id={'contactUs_title_m'} dropdown={dropdownTitle ?? false} backgroundImg={backgroundImgTitle ?? false}/>
        <Container className={`contact_container ${!isDesktopOrLaptop ? 'contact_container_m' : ''} mt-2 mt-lg-0`}>
          <div className={`row contact_container_row`}>
            <PageTitle desktopToggle={desktopTitleToggle} desktopHeadingHide={true} mobileHeadingHide={true} mobileToggle={false} title={'contact_us'} desc={'contact_us_desc'} id={'contactUs_title'} />
            {contactUs.map(function (item, index) {
              // console.log('width: ',contactUs.length > 0 ? 100 / contactUs.length : 0 + '%')
              // (contactUs.length > 3 ? (100 / contactUs.length) - 2) + '%' ): '31%' : '100%'
              // const windowColumn = isDesktopOrLaptop ? contactUs.length > 0 ?( contactUs.length > 3?((100 / contactUs.length) - 2):100%)
              // const windowColumn = isDesktopOrLaptop ? (contactUs.length > 3 ? `${(100 / contactUs.length) - 2}%` : '31%') : '100%'
              // const paddingTopColumn = isDesktopOrLaptop ? (contactUs.length > 3 ? `${(100 / contactUs.length) - 4}%` : '29%') : '0'
              // console.log('windowColumn:', windowColumn)
              return (
                <Card className={`contact_card card_${item.name}`} key={item.code} onClick={(item.name === 'Livechat') ? () => openChat() : null}  >
                  <Card.Body>
                    <Card.Img src={item.icon} alt={item.name} />
                    <div className="contact_card_content">
                      <Card.Title>{item.name} <br></br><span><a href={item.value?.url} target="_blank" rel="noopener noreferrer">{item.value?.account}</a></span></Card.Title>
                      {(item.value?.qr) && (
                        <img src={item.value?.qr.url} alt={item.name} onClick={() => onClickImage(item)} />
                      )}
                    </div>
                  </Card.Body>

                  {(currentImage) && (<Modal show={showContactImgDialog}
                    onHide={() => setShowContactImgDialog(false)}
                    id="contactImgDialog"
                    centered >
                    <Modal.Header closeButton>
                      {currentImage.name}
                    </Modal.Header>
                    <Modal.Body closeButton>
                      <img src={currentImage.value?.qr.url} alt={currentImage.name} />
                    </Modal.Body>
                  </Modal>
                  )}
                </Card>
                //  </div>
              )
            })}
            {/* <div className="col-md-4 mt-md-0 mt-lg-5 mb-0 mb-lg-5">
              <Card className="contact_card">
                <Card.Body>
                  <Card.Img src={`${TENANT_BUCKET}/icon/contactUs_telegram.png`} alt="contact_telegram" />
                  <Card.Title>Telegram: <span>@ FlexGaming</span></Card.Title>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4 mt-md-0 mt-lg-5 mb-0 mb-lg-5">
              <Card className="contact_card">
                <Card.Body>
                  <Card.Img src={`${TENANT_BUCKET}/icon/contactUs_wechat.png`} alt="contact_wechat" />
                  <Card.Title>Wechat:  <span>@ FlexGaming</span></Card.Title>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4 mt-md-0 mt-lg-5 mb-0 mb-lg-5">
              <Card className="contact_card">
                <Card.Body>
                  <Card.Img src={`${TENANT_BUCKET}/icon/contactUs_chatwithus.png`} alt="contact_livechat" />
                  <Card.Title>Livechat:  <span>@ FlexGaming</span></Card.Title>
                </Card.Body>
              </Card>
            </div> */}
          </div>
        </Container>
      </div >
    </section >
  );
};


export default ContactUs;
