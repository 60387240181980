import React, { useState, useEffect, useReducer } from "react";
import { useStoreState, useStoreDispatch, APP_STORE_ACTION } from '../common/storeContext';
import { Modal, Nav } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { BsExclamationCircleFill } from "react-icons/bs"
import { useMiddletier } from "../common/middletier";
import { TENANT_BUCKET } from '../common/constants'
import { useNavigate } from "react-router-dom";
import Alert from './Alert'
import Loading from './Loading';
import { useMediaQuery } from 'react-responsive';
import QRCode from 'qrcode.react';
import {
  WhatsappShareButton,
  TelegramShareButton,
  FacebookMessengerShareButton,
  LineShareButton,
  ViberShareButton,
} from "react-share"
import './ShareQRDialog.css';


const ShareQR = (props) => {
  const { url, avatar } = props
  const { t } = useTranslation();
  const appState = useStoreState()

  return (
    <div className="my_QRcode_container">
      <div className="shareQR_info">
        <div className="shareQR_userInfo">
          <img src={avatar} alt="avatar" />
          <label>{appState?.user?.username}</label>
        </div>
        <div className="shareQR_qrCode">
          <QRCode value={url} renderAs="svg" />
          <div>
            <label>{url}</label>
            <img src={`${TENANT_BUCKET}/icon/share_link.png`} alt="share link" />
            </div>
        </div>
      </div>
      <div className="shareQR_shareMethodWrap">
        <label className="shareQR_title">{t('choose_share_method')}</label>
        <div className="shareQR_shareMethod">
          <WhatsappShareButton url={url} title={t('shareURL_title')}>
            <div>
              <img src={`${TENANT_BUCKET}/icon/shareLink_whatsapp.png`} alt="whatsapp-icon" />
              <p>Whatsapp</p>
            </div>
          </WhatsappShareButton>
          <TelegramShareButton url={url} title={t('shareURL_title')}>
            <div>
              <img src={`${TENANT_BUCKET}/icon/shareLink_telegram.png`} alt="telegram-icon" />
              <p>Telegram</p>
            </div>
          </TelegramShareButton>
          <FacebookMessengerShareButton url={url} title={t('shareURL_title')}>
            <div>
              <img src={`${TENANT_BUCKET}/icon/shareLink_messenger.png`} alt="messenger-icon" />
              <p>Messenger</p>
            </div>
          </FacebookMessengerShareButton>
          <LineShareButton url={url} title={t('shareURL_title')}>
            <div>
              <img src={`${TENANT_BUCKET}/icon/shareLink_line.png`} alt="line-icon" />
              <p>Line</p>
            </div>
          </LineShareButton>
          {/* <ViberShareButton url={url} title={t('shareURL_title')} >
            <div>
              <img src={`${TENANT_BUCKET}/icon/shareLink_fiber.png`} alt="viber-icon" />
              <p>Viber</p>
            </div>
          </ViberShareButton> */}
        </div>
      </div>
    </div>)
}

const ShareQRDialog = (props) => {
  const { shareURL, avatarLogo } = props
  const [promoClaimInfo, setPromoClaimInfo] = useState([])
  const { t } = useTranslation();
  const { query, mutation } = useMiddletier()
  const appState = useStoreState()
  const appDispatch = useStoreDispatch()
  const navigate = useNavigate();
  const [showCancelPromotionDialog, setShowCancelPromotionDialog] = useState(false)
  const [selectedPromotion, setSelectedPromotion] = useState('')
  const [isLoading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false)

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)'
  })

  return (
    <>{
      isDesktopOrLaptop ? <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="myQRcodeModal"
        backdropClassName="myQRcodeBackdrop"
        fullscreen={isDesktopOrLaptop ? false : true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="subTitle">
            {t('my_QRcode')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ShareQR url={shareURL} avatar={avatarLogo} />
        </Modal.Body>
      </Modal> : <ShareQR url={shareURL} avatar={avatarLogo} />
    }

      {isLoading && (<Loading />)}
    </>
  );
}

export default ShareQRDialog;
