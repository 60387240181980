import React, { useState, useEffect, useReducer } from "react";
import { useStoreState, useStoreDispatch, APP_STORE_ACTION } from '../common/storeContext';
import { Modal, Nav } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { BsExclamationCircleFill } from "react-icons/bs"
import { useMiddletier } from "../common/middletier";
import { TENANT_BUCKET } from '../common/constants'
import { useNavigate } from "react-router-dom";
import Alert from './Alert'
import Loading from './Loading';

import './PromoClaimDialog.css';


const CLAIM_ACTION = {
  SET_SHOW_DATA: 'SET_SHOW_DATA',
  SET_FILTER_TYPES: 'SET_FILTER_TYPES',
  SET_SELECTED_FILTER_TYPE: 'SET_SELECTED_FILTER_TYPE',
}

const initialClaimData = {
  showData: [],
  filterTypes: [],
  selectedFilterType: {},
}

const claimReducer = (state, action) => {
  switch (action.type) {

    case CLAIM_ACTION.SET_SHOW_DATA: {
      const showData = action.payload
      return { ...state, showData };
    }

    case CLAIM_ACTION.SET_FILTER_TYPES: {
      const filterTypes = [...action.payload]
      return { ...state, filterTypes, selectedFilterType: filterTypes.length > 0 ? { ...filterTypes[0] } : { code: '', name: '' } };
    }

    case CLAIM_ACTION.SET_SELECTED_FILTER_TYPE: {
      const selectedFilterType = state.filterTypes.find((filterTypes) => filterTypes.code === action.payload)
      return { ...state, selectedFilterType };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const PromoClaimDialog = (props) => {
  const [promoClaimInfo, setPromoClaimInfo] = useState([])
  const { t } = useTranslation();
  const { query, mutation } = useMiddletier()
  const appState = useStoreState()
  const appDispatch = useStoreDispatch()
  const navigate = useNavigate();
  const [claimState, claimDispatch] = useReducer(claimReducer, initialClaimData)
  const [showCancelPromotionDialog, setShowCancelPromotionDialog] = useState(false)
  const [selectedPromotion, setSelectedPromotion] = useState('')
  const [isLoading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false)


  const onChangeType = (newValue) => {
    claimDispatch({ type: CLAIM_ACTION.SET_SELECTED_FILTER_TYPE, payload: newValue })
  }

  const onClickCancelPromotionDialog = (newValue) => {
    setSelectedPromotion(newValue)
    setShowCancelPromotionDialog(true)
  }

  // CANCEL PROMOTION
  const confirmCancelPromotion = () => {
    setLoading(true)
    // CHECK PROMOTION
    // let existPromotion = transferState.memberGameProviders.find((e) => e.game_provider === transferState.selectedTransferFromGameProvider)

    mutation([{
      method: 'cancelPromotion',
      params: [{ code: 'id', graphqlType: 'String', required: true, value: selectedPromotion ?? '' }],
      attributes: []

    }])
      .then(({ data }) => {
        setLoading(false)
        appDispatch({
          type: APP_STORE_ACTION.SHOW_ALERT,
          payload: { description: 'Cancel Promotion Successful', typeAlert: 'success' }
        });

        setRefresh(!refresh)
        return
      })
      .catch((error) => {
        setLoading(false)
        if (error?.networkError?.statusCode === 401) {
          appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
        } else {
          appDispatch({
            type: APP_STORE_ACTION.SHOW_ALERT,
            payload: { description: error.message.toString(), typeAlert: 'error' }
          });
        }
      })
  }

  // useEffect(() => {
  //   if (appState.loggedIn) {
  //     const method = 'promotion_applications'
  //     query({
  //       method,
  //       params: [
  //         { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { member: appState?.user?.username, status: 'APPROVED' } } },
  //       ],
  //       attributes: ['id', 'game_provider_label', 'promotion_label', 'target_amount', 'current_target_amount']

  //     }).then(({ data }) => {
  //       setPromoClaimInfo(data[method])
  //     }).catch((error) => {
  //       console.log('error: ', error)
  //       if (error?.networkError?.statusCode === 401) {
  //         appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
  //       } else {
  //         appDispatch({
  //           type: APP_STORE_ACTION.SHOW_ALERT,
  //           payload: { description: error.message.toString(), typeAlert: 'error' }
  //         });
  //       }
  //     })

  //   }

  // }, [query, appState.loggedIn, appState.user?.username, props.show, appDispatch, navigate]);

  useEffect(() => {
    if (appState?.disablePromotion) {
      claimDispatch({
        type: CLAIM_ACTION.SET_FILTER_TYPES,
        payload: [{ code: 'COUPON', name: 'coupon_claim' }]
      });
    } else {
      claimDispatch({
        type: CLAIM_ACTION.SET_FILTER_TYPES,
        payload: [{ code: 'PROMOTION', name: 'promo_claim' }, { code: 'COUPON', name: 'coupon_claim' }]
      });
    }

  }, [appState?.disablePromotion]);

  useEffect(() => {
    if (props.show) {
      if (appState.loggedIn) {
        setLoading(true)
        const method = 'getPromotionClaim'
        query({
          method,
          params: [
            { code: 'type', graphqlType: 'String', required: true, value: claimState.selectedFilterType?.code },
          ],
          attributes: []

        }).then(({ data }) => {
          setLoading(false)
          setPromoClaimInfo(data[method])

        }).catch((error) => {
          setLoading(false)
          // console.log('error: ', error)
          if (error?.networkError?.statusCode === 401) {
            appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
          } else {
            appDispatch({
              type: APP_STORE_ACTION.SHOW_ALERT,
              payload: { description: error.message.toString(), typeAlert: 'error' }
            });
          }
        })
      }
    }

  }, [query, appState.loggedIn, appState.user?.username, props.show, appDispatch, navigate, claimState.selectedFilterType?.code, refresh]);


  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="promoClaimModal"
        backdropClassName="promoClaimBackdrop"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="subTitle">
            {t('promo_coupon_claim')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="promoClaim_container">
            <Nav variant="pills" className={`claim_filterTab filterTab tab_pills_color font_tab_pills`} onSelect={(key) => { onChangeType(key) }}>
              {claimState.filterTypes?.map(function (item, index) {
                return (
                  <Nav.Item key={item.code} >
                    <Nav.Link eventKey={item.code} className={`filterTab_selection ${item.code === claimState.selectedFilterType.code ? 'color_active active' : ''}`}>
                      {t(item.name)}
                    </Nav.Link>
                  </Nav.Item>
                )
              })}
            </Nav>
            {
              promoClaimInfo?.length ? (
                promoClaimInfo.map(function (item, index) {
                  return (
                    <div className="promoClaim_content" key={item.id}>
                      <div className="promoClaim_content_row">
                        <label> {t('provider')}</label>
                        <p>{item.game_provider_label}</p>
                      </div>
                      <div className="promoClaim_content_row">
                        <label> {claimState.selectedFilterType?.code === 'COUPON' ? t('coupon') : t('promotion')}</label>
                        <p>{item.promotion_label}</p>
                      </div>
                      <div className="promoClaim_content_row">
                        <label> {t('target_type')}</label>
                        <p>{t(`${item.target_type.toLowerCase().replace("_", "")}`)}</p>
                      </div>
                      <div className="promoClaim_content_row">
                        <label> {t('winover_turnover_target')}</label>
                        <p>{parseFloat(item.current_target_amount ?? 0).toLocaleString(appState.currencyFormat?.locale)} / {parseFloat(item.target_amount ?? 0).toLocaleString(appState.currencyFormat?.locale)}</p>
                      </div>
                      <div className="promoClaim_content_row">
                        <div>
                          <BsExclamationCircleFill className="me-2" />
                          <span>{t('promo_claim_reminder')}</span>
                        </div>
                        {appState.cancelPromotion && <button type="button" className="font_button color_button btnCancel btnCancelPromotion" onClick={() => onClickCancelPromotionDialog(item.id)}>{t('cancel')}</button>}
                      </div>
                    </div>
                  )
                })
              ) : (<div className="d-flex align-items-center justify-content-center flex-column">
                <img src={`${TENANT_BUCKET}/icon/noRecord.png`} alt={''} className="no_record_img" />
                <span className="pt-1">{t('no_record')}</span>
              </div>)
            }
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showCancelPromotionDialog} onHide={() => setShowCancelPromotionDialog(false)} centered id="cancelPromotionDialog" backdrop="static" backdropClassName="CancelPromotionBackdrop">
        <Modal.Header>
          <Modal.Title>{t('withdraw_with_activePromotion_title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('withdraw_with_activePromotion_title_content')}</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="cancel_button" onClick={() => setShowCancelPromotionDialog(false)}>
            {t('cancel')}
          </button>
          <button className="confirm_button" onClick={() => {
            // handleSubmit({ forceWithdraw: true });
            confirmCancelPromotion()
            setShowCancelPromotionDialog(false);
          }}>
            {t('confirm')}
          </button>
        </Modal.Footer>
      </Modal>
      {isLoading && (<Loading />)}
    </>
  );
}

export default PromoClaimDialog;
