


import SECTION_SLOTGAMEBANNERSECTION from
'../dynamic_section/dynamic_2/template_01';






import SlotSection from '../sections/slots/template_01';






import SECTION_FOOTER_BANNER from
'../dynamic_section/dynamic_2/template_01';



import "./style.css";


const SlotPage = ()=>{
  
return (
  <div id="slotPage" class="page ">
  
  

  
  < SECTION_SLOTGAMEBANNERSECTION desktopToggle={ true }
    mobileToggle={ false } label={ `slot game banner` } id={ `slot_banner` } supportLanguage={
    true } titleToggle={ false } titleIconToggle={
    true } linkTo={ `` } addContainer={ false } />
  
  
  
  

  
  <SlotSection imageSize={ `1x2` } rowMobile={
    3 } rowDesktop={ 5 } gameProviderNameToggle={
    true } contentPosition={`` } appFolderImage={``} gameProviderFolderImage={``}/>
  
  
  
  

  
  {/* < SECTION_FOOTER_BANNER desktopToggle={ true }
    mobileToggle={ false } label={ `Dynamic Section2` } id={ `footer_banner` } supportLanguage={
    true } titleToggle={ false } titleIconToggle={
    true } linkTo={ `` } addContainer={ false } /> */}
  
  
  

  
</div>
)
}

export default SlotPage;