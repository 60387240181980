import { useEffect, useState } from 'react';
import { Accordion, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AiOutlineUp } from "react-icons/ai";
import { useLocation } from 'react-router-dom';
import { useMiddletier } from "../../common/middletier";
import Loading from '../../components/Loading';
import PageTitle from '../pageTitle/template_01';
import './template_01.css';

const Help = (props) => {
  const { desktopTitleToggle, mobileTitleToggle, dropdownTitle, backgroundImgTitle, desktopToggle, mobileToggle } = props;
  const [selectedKey, setSelectedKey] = useState('#about-us')
  const location = useLocation()
  const [isVisible, setIsVisible] = useState(false);
  const { i18n } = useTranslation();
  const { queries } = useMiddletier();
  const [helpContent, setHelpContent] = useState([]);
  const [isLoading, setLoading] = useState(false);

  // const pathname = window.location.pathname.split("/").pop()

  // const checkDefaultActive =
  //   pathname === 'about-us' ? '0' : (pathname === 'term-and-conditions' ? '1' : (pathname === 'faq') ? '2' : (
  //     pathname === 'how-to-deposit' ? '3' : (pathname === 'how-to-transfer' ? '4' : (pathname === 'how-to-withdraw' ? '5' : '0'))
  //   ))
  //   console.log(checkDefaultActive)



  useEffect(() => {
    setLoading(true)

    queries([{
      index: 'getFaq',
      method: 'getFaq',
      params: [
        { code: 'language', graphqlType: 'String', required: true, value: i18n.resolvedLanguage },
        { code: 'category', graphqlType: 'String', required: false, value: 'HELP' }
      ],
      attributes: []
    }])
      .then(({ data }) => {
        setLoading(false)
        setHelpContent(data['getFaq'])

      }).catch((error) => {
        setLoading(false)
        console.log('error: ', error)
      })

  }, [queries, i18n.resolvedLanguage]);


  const onSelect = (eventKey) => {
    setSelectedKey(eventKey)
  }

  useEffect(() => {
    setSelectedKey(location.hash)
    const element = document.getElementById(location.hash.substring(1));
    if (element) {
      // console.log('lement.offsetParent.offsetTop',element.offsetParent.offsetTop)
      if (element.offsetParent) {
        const yOffset = element.offsetParent.offsetTop;
        window.scrollTo({ top: yOffset, behavior: 'smooth' });
      }
    }

  }, [location])


  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 200) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  if ((desktopToggle || mobileToggle) === true) {
    return (
      <>
        <section id="help_01" className={`section ${desktopToggle ? "" : "d-lg-none"} ${mobileToggle ? "" : "d-none d-lg-block"}`}>
          <div className='help_body'>
            <PageTitle desktopToggle={desktopTitleToggle} mobileToggle={mobileTitleToggle} title={'help'} id={'help_title'} dropdown={dropdownTitle ?? false} backgroundImg={backgroundImgTitle ?? false} />
            <Container>
              <Accordion activeKey={selectedKey} className='help_accordion' onSelect={onSelect}>
                {helpContent?.map(function (item, index) {
                  return (
                    <Accordion.Item eventKey={`#${item?.faq}`} id={item?.faq} key={item?.faq}>
                      <Accordion.Header>{item.title}</Accordion.Header>
                      <Accordion.Body>
                        <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                      </Accordion.Body>
                    </Accordion.Item>
                  )
                })}

                {/* <Accordion.Item eventKey="#about-us" id="about-us">
                <Accordion.Header>{t('about_us')}</Accordion.Header>
                <Accordion.Body>
                  <div dangerouslySetInnerHTML={{ __html: t('help_about_us_content') }}></div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="#term-and-conditions" id="term-and-conditions">
                <Accordion.Header>{t('tnc')}</Accordion.Header>
                <Accordion.Body>
                  <div dangerouslySetInnerHTML={{ __html: t('help_tnc_content') }}></div>

                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="#faq" id="faq">
                <Accordion.Header>{t('faq')}</Accordion.Header>
                <Accordion.Body>
                  <div dangerouslySetInnerHTML={{ __html: t('help_faq_content') }}></div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="#how-to-deposit" id="how-to-deposit">
                <Accordion.Header>{t('how_to_deposit')}</Accordion.Header>
                <Accordion.Body>
                  <div dangerouslySetInnerHTML={{ __html: t('help_how_to_deposit_content') }}></div>

                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="#how-to-transfer" id="#how-to-transfer">
                <Accordion.Header>{t('how_to_transfer')}</Accordion.Header>
                <Accordion.Body>
                  <div dangerouslySetInnerHTML={{ __html: t('help_how_to_transfer_content') }}></div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="#how-to-withdraw" id="#how-to-withdraw">
                <Accordion.Header>{t('how_to_withdrawal')}</Accordion.Header>
                <Accordion.Body>
                  <div dangerouslySetInnerHTML={{ __html: t('help_how_to_withdrawal_content') }}></div>
                </Accordion.Body>
              </Accordion.Item> */}
              </Accordion>
            </Container>
          </div>
        </section>
        {isVisible && (
          <button onClick={scrollToTop} className="scrollToTopButton" >
            <AiOutlineUp />
          </button>
        )}
        {isLoading && (<Loading />)}

      </>
    );
  }
}

export default Help;